import { colours } from "styling/colours";
import { style } from "typestyle";

export const radioButtonStyle = style({
  position: "relative",
  $nest: {
    "input[type=radio]": {
      display: "none",
    },
    "label:before": {
      content: "''",
      display: "inline-block",
      position: "relative",
      top: "3px",
      margin: "0 10px 0 0",
      width: "16px",
      height: "16px",
      borderRadius: "11px",
      border: `1px solid ${colours.tertiaryText}`,
      backgroundColor: "transparent",
    },
    "input[type=radio]:checked + label:after": {
      borderRadius: "11px",
      width: "10px",
      height: "10px",
      position: "absolute",
      top: "6px",
      left: "3px",
      content: "''",
      display: "block",
      background: colours.secondary,
    },
    "input[type=radio]:checked + label:before": {
      borderColor: colours.secondary,
    },
    "input[type=radio]:hover + label:before": {
      borderColor: colours.secondary,
    },
    "input[type=radio]:disabled + label:after": {
      background: "rgba(188, 190, 192, 0.3)",
    },
    "input[type=radio]:disabled + label:before": {
      borderColor: "rgba(188, 190, 192, 0.3)",
    },
    "input[type=radio]:checked:disabled + label:after": {
      opacity: "0.5",
      background: "#00aeef",
    },
    "input[type=radio]:checked:disabled + label:before": {
      opacity: "0.5",
      borderColor: "#00aeef",
    },
  },
});
