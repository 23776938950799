import { icons } from "assets/icons";
import { BullpointCircle } from "components/BullpointCircle";
import { Horizontal } from "gls";
import React from "react";
import { SortableElement, SortableElementProps } from "react-sortable-hoc";
import { colours } from "styling/colours";
import { style } from "typestyle";

type OrderableListItemProps = SortableElementProps & {
  item: React.ReactNode;
  idx: number;
  nItems: number;
  canRearrange?: boolean;
  isMobileWidth?: boolean;
};

const OrderableListItem : React.ComponentClass<OrderableListItemProps, any> = SortableElement(
  ({
    item,
    idx,
    nItems,
    canRearrange,
    isMobileWidth,
  }: OrderableListItemProps) => (
    <Horizontal
      spacing={7}
      verticalAlign="center"
      className={style({
        cursor: canRearrange ? "grab" : undefined,
        position: "relative",
        marginBottom: isMobileWidth && idx < nItems ? "20px" : undefined,
      })}
    >
      {canRearrange && (
        <BullpointCircle
          value={idx}
          size={30}
          colour={colours.primaryText}
          background="#ecedef"
          className={
            isMobileWidth
              ? style({
                position: "absolute",
                left: 7,
                top: -37,
              })
              : undefined
          }
        />
      )}
      {item}
      {nItems > 1 && canRearrange && !isMobileWidth && (
        <icons.ReOrderIcon fill="#808285" />
      )}
    </Horizontal>
  )
);

export default OrderableListItem;
