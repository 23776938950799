import { genericGet, genericPost, genericPut, blobDownload } from "api/config/base";
import { UserDto, UsersListDto } from "views/config/userManagement/UserDto";
import { getConfigApiUrl } from "utils/utils";

export function getUserGroups(): Promise<string[]> {
  return Promise.resolve(["analytics", "iota"])
};

export function getUsers(): Promise<UsersListDto> {
  // return genericGet<UsersListDto>(`${getConfigApiUrl()}/v1/users`);
  return Promise.resolve({
    "version": "af1f14bee14448d8eca1333d97153514277215ca8c344d54ab026895567360e4",
    "users": [
      {
        "id": "70dad306-8655-5217-b955-c4d549a34653",
        "name": " Baseview Porter",
        "username": "baseview1",
        "email": "baseview1@example.com",
        "enabled": true,
        "federated": false,
        "is_resend_welcome_email_available": false,
        "groups": [
          "iota"
        ],
        "workgroups": {
          "00000000-0000-0000-0000-000000000010": [
            "25328d9d-165d-485c-822d-7f4735986bc5"
          ]
        }
      },
      {
        "id": "a0734dd3-790c-5d8a-9c12-802911334e18",
        "name": " Baseview Porter",
        "username": "baseview2",
        "email": "baseview2@example.com",
        "enabled": true,
        "federated": false,
        "is_resend_welcome_email_available": false,
        "groups": [
          "iota"
        ],
        "workgroups": {
          "00000000-0000-0000-0000-000000000010": [
            "25328d9d-165d-485c-822d-7f4735986bc5",
            "c25ad421-44ee-4110-975b-047f8ccea680"
          ]
        }
      },
      {
        "id": "499bc4a0-b85f-5b3c-8615-6037893276ec",
        "name": "BaseCreate Porter",
        "username": "basecreate1",
        "email": "basecreate1@example.com",
        "enabled": true,
        "federated": false,
        "is_resend_welcome_email_available": false,
        "groups": [
          "iota"
        ],
        "workgroups": {
          "00000000-0000-0000-0000-000000000010": [
            "00000000-0000-0000-0000-000000000017"
          ]
        }
      },
      {
        "id": "29234558-b7f9-5434-9139-f18d80499f30",
        "name": "BaseUser Porter 1",
        "username": "base1",
        "email": "base1@example.com",
        "enabled": true,
        "federated": false,
        "is_resend_welcome_email_available": false,
        "groups": [
          "iota"
        ],
        "workgroups": {
          "00000000-0000-0000-0000-000000000010": [
            "c25ad421-44ee-4110-975b-047f8ccea680"
          ]
        }
      }
    ],
    "is_import_in_progress": false,
    "is_failed_import": false,
    "max_import_users_count": 1000
  })
}

export function getLoggedInUser(): Promise<UserDto> {
  return genericGet<UserDto>(`${getConfigApiUrl()}/v1/user`);
}

export function AddUser(user: UserDto): Promise<any> {
  return genericPost<UserDto, any>(`${getConfigApiUrl()}/v1/user`, { user: user })
}

export function UpdateUser(user_id: string, user: UserDto): Promise<any> {
  return genericPut(`${getConfigApiUrl()}/v1/user/${user_id}`, { user })
}

export function assignSelfRoles(roles: string[]) {
  return genericPut(`${getConfigApiUrl()}/v1/self/roles`, roles)
}

export function ResetUserPassword(user_id: string) {
  return genericPut(`${getConfigApiUrl()}/v1/user/${user_id}/reset_password`)
}

export function exportUsers(filters: Record<string, string>): Promise<any> {
  return blobDownload(`${getConfigApiUrl()}/v1/users/export`);
}
