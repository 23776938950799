import { colours } from "styling/colours";
import { style } from "typestyle";

export const checkboxStyle = (disabled = false) =>
  style({
    lineHeight: "22px",
    $nest: {
      'input[type="checkbox"]': {
        position: "relative",
        width: "16px",
        height: "16px",
        color: "black",
        border: `1px solid ${
          !disabled ? colours.tertiaryText : colours.disableSelect
        }`,
        background: !disabled ? colours.white : colours.disableSelect,
        borderRadius: "3px",
        appearance: "none",
        outline: 0,
        cursor: "pointer",
        transition: "background 175ms cubic-bezier(0.1, 0.1, 0.25, 1)",
        $nest: {
          "&:disabled": {
            cursor: "default",
            pointerEvents: "all",
          },
          "&::before": {
            position: "absolute",
            content: "''",
            display: "block",
            top: "1px",
            left: "4px",
            width: "6px",
            height: "10px",
            borderStyle: "solid",
            borderColor: "white",
            borderWidth: "0 2px 2px 0",
            transform: "rotate(45deg)",
            opacity: 0,
          },
          "&:checked": {
            color: "white",
            borderColor: !disabled
              ? colours.secondary
              : colours.secondaryDisabled,
            background: !disabled
              ? colours.secondary
              : colours.secondaryDisabled,
            $nest: {
              "&::before": {
                opacity: 1,
              },
              "label::before": {
                clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
              },
            },
          },
        },
      },
    },
  });
