import { Label } from "components/Label";
import { Vertical } from "gls";
import React from "react";
import ReactSelect, {
  MultiValue,
  SingleValue,
} from "react-select";
import { classes } from "typestyle";
import {
  dropIconStyle,
  selectContainerStyles,
  selectStyles,
  selectTheme,
} from "./selectStyles";
import useDevice, { MIN_SELECT_MENU_HEIGHT } from "hooks/useDevice";
import { KeyValuePair } from "utils/common";
import { ComponentSchema } from "components/Renderer/Renderer";

type SelectProps = {
  id?: string;
  label?: string;
  name?: string;
  value: any;
  placeholder?: string;
  options: KeyValuePair<string>[];
  required?: boolean;
  className?: string;
  disabled?: boolean;
  ariaLabel?: string;
  onChange: (v: any) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  borderless?: boolean;
  width?: string;
  dataTestId?: string;
  helperText?: string;
};

type OptionType = { label: string; value: string };

export const Select = (props: SelectProps) => {

  const val = (props.options || []).find((v) => props.value === v.value);
  const options = (props.options || []).map((o) => ({
    label: o.key,
    value: o.value,
  }));
  const ariaLabel = props.ariaLabel || "Select";
  const placeHolder = props.disabled ? " " : props.placeholder || "Enter the Keyword";
  const { isMobileDevice: isMobileWidth } = useDevice();


  const handleChange = (v: MultiValue<OptionType> | SingleValue<OptionType>) => {
    const value = (v as OptionType).value;

    props.onChange(value);
  };

  return (
    <Vertical
      spacing={0}
      id={ariaLabel}
      className={classes(
        selectContainerStyles(
          props.borderless ? "fit-content" : props.width,
          isMobileWidth
        ),
        props.className
      )}
      aria-label={ariaLabel}
      data-testid={props.dataTestId}
    >
      {props.label && (
        <Label
          htmlFor={props.name}
          value={props.label}
          required={props.required}
        />
      )}
      <ReactSelect
        id={props.id || `${ariaLabel} Input`}
        isDisabled={props.disabled}
        isSearchable={false}
        options={options}
        placeholder={placeHolder}
        theme={selectTheme}
        styles={selectStyles(isMobileWidth, props.borderless)}
        value={val ? { label: val.key, value: val.value } : null}
        onChange={handleChange}
        menuPortalTarget={document.body}
        menuPlacement="auto"
        className={dropIconStyle(props.disabled)}
        classNamePrefix="scrollable"
        closeMenuOnScroll={(e) =>
          (e.target as HTMLElement).className.indexOf("scrollable") === -1
        }
        aria-label={`${ariaLabel} Input`}
        minMenuHeight={isMobileWidth ? undefined : MIN_SELECT_MENU_HEIGHT}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
      />
    </Vertical>
  );
};

export const SelectDefaultProps = {
  label: "Select",
  options: [],
  defaultValue: "",
};

export const SelectOptions: ComponentSchema = {
  label: "Select",
  type: "Select",
  icon: "SelectIcon",
  propsSchema: [
    {
      id: "label",
      type: "Input",
      props: {
        label: "Label",
      },
    },
    {
      id: "placeholder",
      type: "Input",
      props: {
        label: "Placeholder",
      },
    },
    {
      id: "options",
      type: "KeyValuePairs",
      props: {
        label: "Key/Value Pairs",
      },
    },
    {
      id: "ariaLabel",
      type: "Input",
      props: {
        label: "Aria Label",
      },
    },
  ],
};
