import { icons } from "assets/icons";
import { Content, Horizontal } from "gls/lib";
import React from "react";
import { colours } from "styling/colours";
import { classes, style } from "typestyle";

export type ErrorProps = {
  message?: string;
  id: string;
  className?: string;
};

export const Error: React.FC<ErrorProps> = (props: ErrorProps) => {
  if(!props.message || props.message === ""){
    return <></>
  }
  return (
    <Horizontal verticalAlign="top" spacing={6} className={classes(errorStyle,props.className)}>
      <Content>
        <icons.ErrorBangIcon />
      </Content>
      <span key={`baseinput-err-${props.id}`}>{props.message}</span>
    </Horizontal>
  );
};

const errorStyle = style({
  fontWeight: "normal",
  fontSize: "12px",
  color: colours.error,
  marginTop: ".3rem",
  maxWidth: "100%",
});
