import { min } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { leadingZeros } from "utils/numeric";

export type useTimePickerArgs = {
  format: "hh:mm" | "hh:mm:ss";
  minHour: number | undefined;
  minMinutes: number | undefined;
  maxHour: number | undefined;
  maxMinute: number | undefined;
  value: string;
  onChange: (v: string) => void;
};

export function useTimePicker({
  format,
  minHour,
  minMinutes,
  maxHour = 23,
  maxMinute = 59,
  onChange,
  value,
}: useTimePickerArgs) {
  const [hour, setHour] = useState<string>("");
  const [minute, setMinute] = useState<string>("");

  const hourOptions = useMemo(() => {
    const hours = [];

    for (let i = minHour || 0; i <= (min([23, maxHour]) || 23); i++) {
      const hour = moment(i, "H").format("HH");
      hours.push({
        key: hour,
        value: hour,
      });
    }

    return hours;
  }, [maxHour, minHour]);

  const minuteOptions = useMemo(() => {
    const minutes = [];
    const computedMinMinutes = +hour === minHour ? minMinutes : 0;
    const computedMaxMinutes = +hour === maxHour ? maxMinute : 59;

    for (let i = computedMinMinutes || 0; i <= computedMaxMinutes; i++) {
      const minute = moment(i, "m").format("mm");
      minutes.push({
        key: minute,
        value: minute,
      });
    }

    return minutes;
  }, [hour, maxHour, minMinutes, minHour, maxMinute]);

  const handleHourChange = (hour: string, minute: string) => {
    onChange(`${hour || "00"}:${minute || "00"}`);
  };

  const handleMinuteChange = (hour: string, minute: string) => {
    onChange(`${hour || "00"}:${minute || "00"}`);
  };

  const handleOnChange = useCallback((value: string) => {
    onChange(value ? `${value}${format === "hh:mm:ss" ? ":00" : ""}` : value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const timeRegex = /^([0-1]?[0-9]|2[0-3]):\s?([0-5][0-9]):?([0-5][0-9])?$/;
    const match = (value || "").match(timeRegex);
    const hours = match && match.length > 2 ? match[1] : "";
    const minutes = match && match.length > 2 ? match[2] : "";
    const isMinHour = +hours === minHour;

    const actualHours =
      minHour && +hours < minHour ? leadingZeros(minHour, 2) : hours;

    const actualMinutes =
      isMinHour && minMinutes && +minutes < minMinutes
        ? leadingZeros(minMinutes, 2)
        : minutes;

    setHour(hours);
    setMinute(actualMinutes);
    handleOnChange(
      actualHours && actualMinutes ? `${actualHours}:${actualMinutes}` : ""
    );
  }, [value, minHour, minMinutes, handleOnChange]);

  return {
    handleHourChange,
    handleMinuteChange,
    hour,
    hourOptions,
    minute,
    minuteOptions,
  };
}
