import { icons } from "assets/icons";
import { Label } from "components/Label";
import { Horizontal, Vertical } from "gls/lib";
import { style } from "typestyle";
import { SearchableSelect } from "../Select/SearchableSelect";
import { useTimePicker } from "./hooks/useTimePicker";
import { colours } from "styling/colours";
import { ComponentSchema } from "components/Renderer/Renderer";

type TimePickerProps = {
  ariaLabel?: string;
  label?: string;
  minHour?: number;
  minMinutes?: number;
  maxHour?: number;
  maxMinute?: number;
  required?: boolean;
  value: string;
  onChange: (v: string) => void;
  isClearable?: boolean;
  hideIcon?: boolean;
  hourPlaceholder?: string;
  minutePlaceholder?: string;
  disabled?: boolean;
  error?: string;
  format?: "hh:mm" | "hh:mm:ss";
  id?: string;
};

export const TimePicker = (props: TimePickerProps) => {
  const {
    minHour,
    minMinutes,
    maxHour,
    maxMinute,
    ariaLabel = "Time Picker",
    isClearable = false,
    hideIcon = false,
    hourPlaceholder = "HH",
    minutePlaceholder = "MM",
    disabled = false,
    error,
    format = "hh:mm",
    id = ariaLabel,
  } = props;
  const {
    handleHourChange,
    handleMinuteChange,
    hour,
    hourOptions,
    minute,
    minuteOptions,
  } = useTimePicker({
    format,
    minHour,
    minMinutes,
    maxHour,
    maxMinute,
    value: props.value,
    onChange: props.onChange,
  });

  return (
    <Vertical spacing={0} aria-label={ariaLabel} id={ariaLabel}>
      {props.label && <Label value={props.label} required={props.required} />}
      <Horizontal spacing={10} verticalAlign="center">
        <SearchableSelect
          value={hour}
          options={hourOptions}
          onChange={(hour) => handleHourChange(hour, minute)}
          placeholder={hourPlaceholder}
          ariaLabel={`${ariaLabel} Hour`}
          id={`${id} Hour`}
          width="95px"
          isClearable={isClearable}
          disabled={disabled}
          showPlaceholderOnDisabled
          error={error}
          hideErrorText
        />
        <SearchableSelect
          value={minute}
          options={minuteOptions}
          onChange={(min) => handleMinuteChange(hour, min)}
          placeholder={minutePlaceholder}
          ariaLabel={`${ariaLabel} Minute`}
          id={`${id} Minute`}
          width="95px"
          isClearable={isClearable}
          disabled={disabled}
          showPlaceholderOnDisabled
          error={error}
          hideErrorText
        />
        {!hideIcon &&
          <icons.ClockIcon
            width={20}
            height={20}
            fill={colours.secondaryText}
            className={style({
              display: "inline-block",
              margin: "0 16px !important",
            })}
          />
        }
      </Horizontal>
    </Vertical>
  );
};

export const TimePickerOptions: ComponentSchema = {
  label: "Time Picker",
  type: "TimePicker",
  icon: "ClockIcon",
  propsSchema: [
    {
      id: "label",
      type: "Input",
      props: {
        label: "Label",
      },
    },
    {
      id: "ariaLabel",
      type: "Input",
      props: {
        label: "Aria Label",
      },
    },
  ],
};
