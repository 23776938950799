import { Horizontal, Vertical } from "gls/lib";
import { isArray, isEmpty } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { classes, media, style } from "typestyle";
import { Label } from "../../Label";
import { Error } from "../Error";
import { isNaN } from "lodash";
import { HelperText } from "../HelperText";
import { useValidation } from "hooks/useValidation";
import { colours } from "styling/colours";
import { MOBILEDEVICEWIDTH } from "config/constants";
import { inputBaseStyle } from "../Input/BaseInput";
import { ComponentSchema } from "components/Renderer/Renderer";

export type StepperControlProps = {
  name: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  value: number;
  error?: string | string[];
  min?: number;
  max?: number;
  ariaLabel?: string;
  alignment?: "vertical" | "horizontal";
  onChange: (value: number, errors?: string[]) => void;
  dataTestId?: string;
  helperText?: string;
};

export const StepperControlDefaultProps = {
  label: "Stepper Control",
  placeholder: "numbers only",
  min: 0,
};

export function StepperControl(props: StepperControlProps) {
  const {
    ariaLabel = "Stepper Control",
    max,
    min = 0,
    onChange,
    required,
    disabled,
    value,
    error,
    ...rest
  } = props;

  const [disabledAdd, setDisabledAdd] = useState(false);
  const [disabledMinus, setDisabledMinus] = useState(false);

  const { validate } = useValidation(
    "number",
    { ...rest, max, min },
    error || ""
  );
  const setButtonsState = useCallback(
    (newValue: number | undefined) => {
      if (disabled === true) {
        setDisabledAdd(true);
        setDisabledMinus(true);
      }
      else {
        setDisabledAdd(max !== undefined ? (newValue || 0) >= max : false);
        setDisabledMinus(min !== undefined ? (newValue || 0) <= min : false);
      }
    },
    [max, min, disabled]
  );

  useEffect(() => {
    setButtonsState(value);
  }, [value, setButtonsState]);

  useEffect(() => {
    if (!disabled && value === undefined && min !== undefined) {
      onChange(+min, undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled])

  const handleChange = (evt: React.FormEvent<HTMLInputElement>) => {
    const newValue = evt.currentTarget.value;
    const errors = validate(newValue);
    setButtonsState(+newValue);
    onChange(+newValue, errors);
  };

  const handleChangeStep = (newValue: number) => {
    // The following two if clauses are there to handle the case when there's no initial value
    if (isNaN(newValue)) {
      newValue = value ?? min ?? max ?? 0;
    }
    if (typeof newValue === "string") {
      newValue = parseInt(newValue);
    }

    const errors = validate(String(newValue));
    setButtonsState(newValue);
    onChange(newValue, errors);
  };

  const getLabel = () => {
    if (props.label) {
      const label = (
        <Label
          htmlFor={props.name}
          value={props.label}
          required={required}
          className={labelTopPadding(props.alignment)}
        />
      );

      if (props.alignment && props.alignment === "horizontal") {
        return <Vertical className={labelStyle}>{label}</Vertical>;
      }

      return label;
    }

    return null;
  };

  const result = (
    <>
      {getLabel()}
      <Vertical spacing={0}>
        <Horizontal spacing={0}>
          <button
            className={classes(buttonStyle, buttonLeftStyle)}
            onClick={() => handleChangeStep((value || 0) - 1)}
            disabled={disabledMinus}
          >
            -
          </button>

          <input
            className={classes(inputStyle, !isEmpty(error) && errorBorder)}
            type="number"
            value={value ?? ""}
            name={props.name}
            disabled={props.disabled}
            required={required}
            placeholder={props.placeholder}
            onChange={handleChange}
            autoComplete="off"
            aria-label={ariaLabel}
            id={ariaLabel}
            data-testid={props.dataTestId}
          />

          <button
            className={classes(buttonStyle, buttonRightStyle)}
            onClick={() => handleChangeStep((value || 0) + 1)}
            disabled={disabledAdd}
          >
            +
          </button>
        </Horizontal>
        {error &&
          (isArray(error) ? error : [error]).map((e, i) => (
            <Error
              key={`baseinput-err-${i}`}
              id={`baseinput-err-${i}`}
              message={e}
            />
          ))}
      </Vertical>
    </>
  );

  return props.alignment && props.alignment === "horizontal" ? (
    <Horizontal spacing={30} verticalAlign="top">
      {result}
      {props.helperText &&
        <HelperText className={style({ maxWidth: "300px", height: "95%" })} content={props.helperText} />
      }
    </Horizontal>
  ) : (
    <Vertical spacing={0} maxWidth="300px">
      {result}
      {props.helperText &&
        <HelperText content={props.helperText} />
      }
    </Vertical>
  );
}

const labelStyle = style(
  {
    width: 250,
  }
);

const buttonStyle = style(
  {
    width: "50px",
    height: "50px",
    border: `1px solid ${colours.tertiaryText}`,
    borderRadius: "3px",
    backgroundColor: "#e4e5e6",
    fontSize: "17px",
    fontWeight: "bold",
    outline: "none",
    cursor: "pointer",
    color: colours.primaryText,
    transition: "all 0.3s ease-in-out",
    $nest: {
      "&:hover": {
        backgroundColor: colours.secondary,
        color: "white",
        border: "none",
      },
      "&:disabled": {
        backgroundColor: "white",
        color: colours.tertiaryText,
        cursor: "default",
        border: `1px solid ${colours.tertiaryText}`,
      },
    },
  },
  media({ maxWidth: MOBILEDEVICEWIDTH }, { width: 40, height: 40 })
);

const buttonRightStyle = style({
  borderTopLeftRadius: "0 !important",
  borderBottomLeftRadius: "0 !important",
});

const buttonLeftStyle = style({
  borderTopRightRadius: "0 !important",
  borderBottomRightRadius: "0 !important",
});

const inputStyle = style(
  {
    ...inputBaseStyle,
    width: "50px",
    height: "50px",
    padding: "5px 7px",
    borderRadius: 0,
    textAlign: "center",
    borderRight: "none",
    borderLeft: "none",
    $nest: {
      "&:focus": {
        border: `1px solid ${colours.primary}`,
      },
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
    },
  },
  media({ maxWidth: MOBILEDEVICEWIDTH }, { width: 50, height: 40 })
);

const errorBorder = style({
  border: `1px solid ${colours.error}`,
  $nest: {
    "&:focus": {
      border: `1px solid ${colours.error}`,
    },
  },
});

const labelTopPadding = (alignment = "vertical") =>
  style({
    paddingTop: alignment === "horizontal" ? 18 : 0,
  });

export const StepperControlOptions: ComponentSchema = {
  label: "Input",
  type: "StepperControl",
  icon: "StepperControlIcon",
  propsSchema: [
    {
      id: "label",
      type: "Input",
      props: {
        label: "Label",
      },
    },
    {
      id: "placeholder",
      type: "Input",
      props: {
        label: "Placeholder",
      },
    },
    {
      id: "ariaLabel",
      type: "Input",
      props: {
        label: "Aria Label",
      },
    },
    {
      id: "alignment",
      type: "Select",
      defaultValue: "horizontal",
      props: {
        label: "alignment",
      },
    },
  ],
  validationSchema: [
    {
      id: "required",
      type: "Toggle",
      props: {
        label: "Required",
        width: "100%"
      },

    },
    {
      id: "min",
      type: "InputNumber",
      defaultValue: "0",
      props: {
        label: "Minimum Value",
        placeholder: "0-99999999",
      },
    },
    {
      id: "max",
      type: "InputNumber",
      props: {
        label: "Maximum Value",
        placeholder: "0-99999999",
      },
    },
  ],
};