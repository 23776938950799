import { PropsSchema } from "components/Renderer/Renderer";

export const schemaHelper = (schemaProvider: SchemaProvider) => {
  const deleteItem = (
    items: Record<string, any> | undefined,
    itemIndex: number
  ) => {
    if (!items) return {};
    
    const itemPropsSchema = schemaProvider.get(itemIndex);

    const newItems = { ...items };
    itemPropsSchema.forEach((prop) => {
      let i = itemIndex;
      for (; !!newItems[prop.id + (i + 1)]; i++) {
        newItems[prop.id + i] = newItems[prop.id + (i + 1)];
      }
      delete newItems[prop.id + i];
    });

    return newItems;
  };

  const reArrangeItems = (
    items: Record<string, any> | undefined,
    oldIndex: number,
    newIndex: number
  ) => {
    if (!items) return {};
    const itemPropsSchema = schemaProvider.get(newIndex);

    const newItems = { ...items };
    itemPropsSchema.forEach((prop) => {
      const temp = newItems[prop.id + oldIndex];
      newItems[prop.id + oldIndex] = newItems[prop.id + newIndex];
      newItems[prop.id + newIndex] = temp;
    });

    return newItems;
  };

  return {
    deleteItem,
    reArrangeItems,
  };
};


export class SchemaProvider{
  constructor(
    private itemPropsSchema: PropsSchema[], 
    private itemPropsSchemaOverride: Record<number, PropsSchema[]>){
  }

  get(index: number){
    if(index in this.itemPropsSchemaOverride){
      return this.itemPropsSchemaOverride[index];
    }
    return this.itemPropsSchema;
  }

  isValid(){
    // eslint-disable-next-line eqeqeq
    return this.itemPropsSchema != undefined && this.itemPropsSchema.length > 0
  }
};