import AssetsApi from 'api/assets/assets.api';
import { useContext } from 'react';
import { getIdToken } from 'utils/utils';
import { AssetsContext } from '../contexts/assets.context';
import { SearchFilterContext } from '../contexts/searchFilter.context';
import { VenuesContext } from '../contexts/venues.context';
import {
  getAssetsWithBuildingAndEstimate,
  getIntersectionFilteredAssets,
  getOutdoorEstimates,
} from '../utils/utils';
import { MapControlContext } from '../contexts/mapcontrol.context';
import useHandleSingleEstimateReturnedFromSearch from './useHandleSingleEstimateReturnedFromSearch';
import useAuth from 'hooks/useAuth.hook';

export default function useCallAssetsAPIIntervalFunction() {
  const { setRawIndoorAssets, setRawOutsideMappedBuildingsAssets } = useContext(AssetsContext);
  const { cachedEstimateRef } = useContext(SearchFilterContext);
  const { selectedVenueObj, isSingleVenue } = useContext(VenuesContext);
  const selectedVenueID = isSingleVenue ? null : selectedVenueObj.venue_id;
  const { authSignout } = useAuth();
  const {
    inSearchMode,
    inFilterMode,
    assetSubtypeCheckedListArray,
    assetOwnerCheckedListArray,
    assetStatusCheckedListArray,
    searchTerm,
    searchType,
  } = useContext(SearchFilterContext);
  const { setPollAssetsFinished, setShouldRecalculateFloor } = useContext(MapControlContext);

  const { handleSingleEstimateReturnedFromSearch } = useHandleSingleEstimateReturnedFromSearch();

  function callAssetsAPIIntervalFunction() {
    // This function is called on each poll.
    // using localstorage for token instead of context, as we need the latest token each time, and cant use usecontext inside the interval function.
    const localStorageState: any = localStorage.getItem('aws-amplify-cacheauthState');
    if (localStorageState === null) {
      authSignout();
      return;
    }

    const authState = JSON.parse(localStorageState).data;
    const token = getIdToken(authState);
    const assetsApi = new AssetsApi(token);

    async function callDerivedAPIMethod() {
      // If there is a search or filter set by user, we need to use the respective API call for that for the polling.
      if (inSearchMode) {
        return assetsApi.getRegisteredBeaconMapAssets(selectedVenueID, searchTerm);
      } else if (inFilterMode) {
        return assetsApi.getRegisteredBeaconFilteredMapAssets(
          selectedVenueID,
          assetOwnerCheckedListArray,
          assetSubtypeCheckedListArray,
          assetStatusCheckedListArray,
        );
      } else {
        // if no filter / searches, we call  api with only venuename param.
        return assetsApi.getRegisteredBeaconMapAssets(selectedVenueID);
      }
    }
    setPollAssetsFinished(false);
    setShouldRecalculateFloor(false);

    callDerivedAPIMethod().then((res) => {
      const { items } = res.data;
      const estimatesWithBuilding = getAssetsWithBuildingAndEstimate(items);
      const outdoorEstimates = getOutdoorEstimates(items);
      const combinedEstimates = [...estimatesWithBuilding, ...outdoorEstimates];

      if (inFilterMode) {
        // apply intersectional filtering if filtermode is active
        const intersectionalFilteredIndoorAssets = getIntersectionFilteredAssets(
          estimatesWithBuilding,
          assetOwnerCheckedListArray,
          assetSubtypeCheckedListArray,
          assetStatusCheckedListArray,
        );
        const intersectionalFilteredOutdoorAssets = getIntersectionFilteredAssets(
          outdoorEstimates,
          assetOwnerCheckedListArray,
          assetSubtypeCheckedListArray,
          assetStatusCheckedListArray,
        );
        setRawIndoorAssets(intersectionalFilteredIndoorAssets);
        setRawOutsideMappedBuildingsAssets(intersectionalFilteredOutdoorAssets);
      } else {
        setRawIndoorAssets(estimatesWithBuilding);
        setRawOutsideMappedBuildingsAssets(outdoorEstimates);
      }

      if (inSearchMode && searchType === 'assets' && combinedEstimates.length === 1) {
        handleSingleEstimateReturnedFromSearch(combinedEstimates[0], cachedEstimateRef);
      }
      setPollAssetsFinished(true);
    });
  }

  return { callAssetsAPIIntervalFunction };
}
