import { DataGridColumn } from "components/DataGrid/models";
import { isArray, isEmpty, isEqual, keys } from "lodash";
import { action, computed, makeObservable, observable } from "mobx";
import { RootStore } from "stores/RootStore";

export class DataTableStore {
  root: RootStore;

  columns: DataGridColumn[] = [];
  data: Record<string, any>[] = [];
  editingRowIndex: number | undefined = undefined;
  editingRowValues: Record<string, any> = {};
  isNewRow: boolean = false;
  newEmptyRow: Record<string, any> = {};
  gridLoading: boolean = true;
  errors: Record<string, string[]> = {};
  scrollingButtonShow: boolean = true;
  totalDisplayedRows: number = 0;

  constructor(root: RootStore) {
    this.root = root;

    makeObservable(this, {
      columns: observable,
      data: observable,
      editingRowIndex: observable,
      editingRowValues: observable,
      isNewRow: observable,
      gridLoading: observable,
      errors: observable,
      scrollingButtonShow: observable,
      totalDisplayedRows: observable,

      setColumn: action.bound,
      setData: action.bound,
      setGridLoading: action.bound,
      startEditRow: action.bound,
      stopEditingRow: action.bound,
      changeRowValues: action.bound,
      reset: action.bound,
      AddEmptyRow: action.bound,
      validateCellsValue: action.bound,
      setScrollingButtonShow: action.bound,
      setTotalDisplayedRows: action.bound,

      isValidRow: computed,
      isEditingRowChanged: computed
    });
  }

  setScrollingButtonShow(show: boolean) {
    this.scrollingButtonShow = show;
  }

  setTotalDisplayedRows(total: number) {
    this.totalDisplayedRows = total;
  }

  validateCellsValue() {
    this.columns.forEach(col =>
      this.validateCellValue(this.editingRowValues, col.accessor));
  }

  validateCellValue(rowValues: Record<string, any>, columnId: string) {
    const col = this.columns.filter(col => col.accessor === columnId);
    if (col.length === 0) {
      return;
    }
    if (!col[0].validators) {
      return;
    }
    if (col[0].editable && col[0].editable(this.editingRowValues, columnId, this.isNewRow) !== true) {
      return;
    }

    const validators = isArray(col[0].validators) ? col[0].validators : [col[0].validators];
    delete this.errors[columnId];
    validators.forEach(fn => {
      const err = fn(rowValues[columnId], columnId, rowValues, this.isNewRow, this.data);
      if (err && err !== "") {
        this.errors[columnId] = [err];
      }
    })
  }

  setColumn(columns: any) {
    this.columns = columns;
  }

  setData(data: Record<string, any>[]) {
    this.data = data;
  }

  setGridLoading(loading: boolean) {
    this.gridLoading = loading;
  }

  startEditRow(index: number) {
    if (this.editingRowIndex !== undefined) {
      this.stopEditingRow();
    }
    this.editingRowIndex = index;
    this.editingRowValues = { ...this.data[index] };
    this.isNewRow = false;
    for (const column of this.columns) {
      this.validateCellValue(this.editingRowValues, column.accessor);
    }
  }

  stopEditingRow() {
    if (this.isNewRow && this.editingRowIndex !== undefined) {
      const newRows = [...this.data];
      newRows.splice(this.editingRowIndex, 1);
      this.data = newRows;
    }
    this.editingRowIndex = undefined;
    this.editingRowValues = {};
    this.isNewRow = false;
  }

  changeRowValues(value: any, columnId: string) {
    const newValues = {
      ...this.editingRowValues,
      [columnId]: value
    };
    this.editingRowValues = newValues;
    this.validateCellValue(this.editingRowValues, columnId);
  }

  clearFederated() {
    if (!this.editingRowValues["federated"] && this.editingRowValues["name"] === 'N/A') {
      this.editingRowValues["name"] = '';
    }
  }

  reset() {
    this.editingRowIndex = undefined;
    this.editingRowValues = {};
    this.gridLoading = true;
    this.isNewRow = false;
    this.columns = [];
    this.data = [];
    this.errors = {};
  }

  AddEmptyRow(emptyRow?: any, index: number = 0) {
    if (this.isNewRow) {
      return;
    }

    if (!emptyRow) {
      emptyRow = {};
      this.columns.forEach(col => {
        if (col.accessor) {
          emptyRow[col.accessor] = undefined;
        }
      });
    }
    this.newEmptyRow = emptyRow;
    const newRows = [...this.data];
    newRows.splice(index, 0, emptyRow);
    this.data = newRows;
    this.editingRowIndex = index;
    this.editingRowValues = { ...emptyRow };
    this.isNewRow = true;
    this.errors = {};
  }

  get isEditingRowChanged() {
    this.clearFederated();
    if (this.isNewRow) {
      return keys(this.editingRowValues).some(key => !isEqual(this.editingRowValues[key], this.newEmptyRow[key]));
    }
    if (this.editingRowIndex !== undefined) {
      const index: number = this.editingRowIndex;
      return keys(this.editingRowValues).some(key => this.editingRowValues[key] !== this.data[index][key]);
    }
    return false;
  }

  get isValidRow() {
    if (!isEmpty(this.errors)) {
      return false;
    }

    for (const col of this.columns) {
      if (col.required
        && col.required(this.editingRowValues, col.accessor, this.isNewRow)
        && isEmpty(this.editingRowValues[col.accessor])) {
        return false;
      }
    }
    return true;
  }
}
