import { Horizontal } from "gls/lib";
import React from "react";
import { classes, style } from "typestyle";
import { checkboxStyle } from "./checkBoxStyles";
import { ComponentSchema } from "components/Renderer/Renderer";

export enum ExpandClickZone {
  Horizontal = 1,
  Vertical,
  All,
}

export type CheckBoxProps = {
  checked: boolean;
  value: string | number;
  label?: string;
  name: string;
  ariaLabel?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  expandClickZone?: ExpandClickZone;
  containerClassName?: string;
  verticalAlign ?: "bottom" | "center" | "top" | "stretch" | "baseline" | undefined;
  dataTestId?: string;
  id?: string;
};

export const CheckBox = ({
  value,
  checked,
  label,
  onChange,
  name,
  disabled,
  expandClickZone,
  containerClassName,
  verticalAlign,
  dataTestId,
  id = `checklist-${name}-${label}`
}: CheckBoxProps) => {

  return (
    <Horizontal
      spacing={12}
      className={classes(containerClassName, checkboxStyle(disabled))}
      verticalAlign={verticalAlign || "center"}
    >
      <label
        className={wrapperStyle(expandClickZone)}
        onClick={(e) => e.stopPropagation()}
      >
        <input
          id={id}
          data-testid={dataTestId ?? id}
          type="checkbox"
          checked={checked}
          value={value}
          name={name}
          onChange={onChange}
          onClick={(e) => e.stopPropagation()}
          disabled={disabled}
        />
      </label>
      {label && <label htmlFor={id}>{label}</label>}
    </Horizontal>
  );
};

export const CheckBoxOptions: ComponentSchema = {
  label: "CheckBox",
  type: "CheckBox",
  icon: "CheckBoxIcon",
  propsSchema: [
    {
      id: "label",
      type: "Input",
      props: {
        label: "Label",
      },
    },
    {
      id: "ariaLabel",
      type: "Input",
      props: {
        label: "Aria Label",
      },
    },
  ],
};

export const CheckBoxDefaultProps = {
  label: "CheckBox",
  defaultValue: false,
};

const getPadding = (expandClickZone?: ExpandClickZone) => {
  if (!expandClickZone) return undefined;

  if (expandClickZone === ExpandClickZone.All) return "30px";

  if (expandClickZone === ExpandClickZone.Horizontal) return "0 30px";

  if (expandClickZone === ExpandClickZone.Vertical) return "30px 0";
};

const wrapperStyle = (expandClickZone?: ExpandClickZone) =>
  style({
    lineHeight: 0,
    cursor: "pointer",
    padding: getPadding(expandClickZone),
  });
