import { Vertical } from "gls/lib";
import { isArray } from "lodash";
import React, { forwardRef, useEffect, useState } from "react";
import { classes, style } from "typestyle";
import { Label } from "../../Label";
import { ComponentSchema } from "components/Renderer/Renderer";
import { colours } from "styling/colours";
import { BaseInput } from "./BaseInput";
import { GraphemeString } from "utils/utf8";
import { useValidation } from "hooks/useValidation";

export interface InputProps {
  id?: string;
  ariaLabel?: string;
  className?: string;
  disabled?: boolean;
  error?: string | string[];
  containerror?: boolean;
  label?: string;
  type?: string;
  name: string;
  onChange: (value: string, errors?: string[]) => void;
  onClick?: () => void;
  onFocus?: (e?: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e?: React.FocusEvent<HTMLInputElement>) => void;
  placeholder?: string;
  value: string;
  alphaNumeric?: boolean;
  required?: boolean;
  maxLength?: number;
  showMaxLength?: boolean;
  number?: boolean;
  tel?: boolean;
  textOnly?: boolean;
  dirty?: boolean;
  autoFocus?: boolean;
  dataTestId?: string;
  width?: string;
  maxWidth?: string;
  helperText?: string;
}

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    ariaLabel = "Input",
    autoFocus,
    className,
    disabled,
    error,
    containerror,
    label,
    maxLength,
    name,
    onChange,
    placeholder,
    value,
    onClick,
    onFocus,
    onBlur,
    dirty,
    dataTestId,
    maxWidth = "300px",
    width = "100%",
    type = "text",
    ...rest
  } = props;
  const [charactersLeft, setCharactersLeft] = useState(Infinity);
  const { validate, showError, setShowError } = useValidation(
    "text",
    {
      ...rest,
    },
    error || "",
    dirty
  );

  const handleChange = (handleChangeValue: string) => {
    const errors = validate(handleChangeValue);
    const utf8String = new GraphemeString(handleChangeValue);

    if (props.maxLength && utf8String.length > props.maxLength) {
      return props.onChange(utf8String.slice(0, props.maxLength), errors);
    }

    onChange(handleChangeValue, errors);
  };

  useEffect(() => {
    if (maxLength) {
      setCharactersLeft(maxLength - new GraphemeString(value ?? "").length);
    }
  }, [value, maxLength]);

  useEffect(() => {
    if (error) {
      setShowError(true);
    }
  }, [error, setShowError])

  return (
    <Vertical
      spacing={0}
      id={`${name || "Input"}-${ariaLabel}`}
      className={classes(
        style({ width: width, maxWidth: maxWidth, position: "relative" }),
        className
      )}
    >
      {label && (
        <Label htmlFor={name} value={label} required={props.required} />
      )}
      <div className={style({ position: "relative" })}>
        <BaseInput
          value={value}
          onChange={handleChange}
          onClick={onClick}
          onFocus={onFocus}
          onBlur={onBlur}
          name={name}
          type={type}
          disabled={disabled}
          placeholder={placeholder}
          errors={showError && error ? (isArray(error) ? error : [error]) : []}
          aria-label={ariaLabel}
          id={props.id || `${ariaLabel} Input`}
          ref={ref}
          containerror={containerror}
          dataTestId={dataTestId}
          autoFocus={autoFocus}
        />
        {maxLength !== undefined && maxLength > 0 && (
          <div
            className={maxLengthStyle}
            aria-label="textInputContent"
            id={`${ariaLabel} Content`}
          >
            {charactersLeft}
          </div>
        )}
      </div>
    </Vertical>
  );
});

export const InputOptions: ComponentSchema = {
  label: "Input",
  type: "Input",
  icon: "InputIcon",
  propsSchema: [
    {
      id: "label",
      type: "Input",
      props: {
        label: "Label",
      },
    },
    {
      id: "placeholder",
      type: "Input",
      props: {
        label: "Placeholder",
      },
    },
    {
      id: "ariaLabel",
      type: "Input",
      props: {
        label: "Aria Label",
      },
    },
    {
      id: "alignment",
      type: "Select",
      defaultValue: "vertical",
      props: {
        label: "alignment",
      },
    },
    {
      id: "type",
      type: "Select",
      props: {
        label: "Type",
        options: [
          { key: "text", value: "text" },
          { key: "password", value: "password" },
          { key: "tel", value: "tel" },
          { key: "url", value: "url" },
          { key: "hidden", value: "hidden" },
        ],
      },
    },
  ],
  validationSchema: [
    {
      id: "required",
      type: "Toggle",
      props: {
        label: "Required",
      },
    },
    {
      id: "textOnly",
      type: "Toggle",
      props: {
        label: "Text Only",
        width: "500px"
      },
    },
    {
      id: "maxLength",
      type: "InputNumber",
      props: {
        label: "Maximum Length",
        placeholder: "0-99999999",
      },
    },
  ],
};

const maxLengthStyle = style({
  position: "absolute",
  top: '14px',
  right: '7px',
  lineHeight: "24px",
  fontSize: "12px",
  color: colours.secondaryText,
})

export const InputDefaultProps = {
  label: "Input Label",
  type: "text",
  placeholder: "Some text",
  defaultValue: "",
};
