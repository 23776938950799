import { Content } from "gls/lib";
import { Interactions } from "./Interactions";
import { map } from "../inputs/map";
import { style } from "typestyle";
import { PropsSchema } from "./Renderer";

type RendererComponentProps = {
  schema: PropsSchema;
  value: unknown;
  error?: string[];
  onChange: (id: string, value: unknown, errors?: string[]) => void;
  selected?: boolean;
  hovered?: boolean;
  onSelect?: () => void;
  onHoverIn?: () => void;
  onHoverOut?: () => void;
  onBlur?: () => void;
  onFocus?: () => void;
  id?: string;
  index?: number;
  elementIndex?: number;
  dirty?: boolean;
  disabledFields?: Record<string, boolean>;
  isDisabled?: boolean;
  helperText?: string;
};

export const RendererComponent: React.FC<RendererComponentProps> = (
  props: RendererComponentProps
) => {
  const {
    schema,
    value,
    error,
    onChange,
    onBlur,
    onFocus,
    selected,
    hovered,
    onSelect,
    onHoverIn,
    onHoverOut,
    index,
    elementIndex,
    dirty,
    disabledFields,
  } = props;
  let result: React.ReactElement | null = null;

  try {
    const componentType: string = schema && schema.type;
    const componentData = map.get(componentType);

    if (!componentData) {
      throw new Error(`Component ${componentType} does not exist`);
    }

    const handleChange = (v: unknown, errors?: string[]) => {
      if (schema.type === "CheckBox") {
        onChange(schema.id, !value, errors);
      }
      else {
        onChange(schema.id, v ?? "", errors);
      }
    };

    const getComponentWidth = (width?: string) => {
      const regex = /(\d*)(px|%)/;
      const isValidWidth = width && regex.test(width);

      return isValidWidth && width ? width : undefined;
    };

    const width = schema.props?.block
      ? "100%"
      : getComponentWidth(schema.props?.width as string);
    const maxWidth = schema.props?.maxWidth
      ? getComponentWidth(schema.props?.width as string)
      : undefined;

    const id = `${props.id ? `${props.id}-` : ""}element-${index || 0}-${elementIndex || 0}`;
    const schemaProps = schema?.props || {};
    const isDisabled = "disabled" in schemaProps ? schemaProps["disabled"] : false;
    const isDisabledFromFieldsMap = disabledFields ? disabledFields[schema.id] && value : false;

    const component = (
      schema.type === "CheckBox"
        ? <componentData.component
          key={id}
          id={id}
          dataTestId={id}
          name={id}
          className={style({
            width,
            maxWidth,
          })}
          {...schema.props}
          checked={value}
          error={error}
          onChange={handleChange}
          onBlur={onBlur}
          onFocus={onFocus}
          dirty={dirty}
          disabled={isDisabled || isDisabledFromFieldsMap || props.isDisabled} //adding value is a workaround.
          helperText={schema.helperText}
        />
        : <componentData.component
          key={id}
          id={id}
          dataTestId={id}
          name={id}
          className={style({
            width,
            maxWidth,
          })}
          {...schema.props}
          value={value}
          error={error}
          onChange={handleChange}
          onBlur={onBlur}
          onFocus={onFocus}
          dirty={dirty}
          disabled={isDisabled || isDisabledFromFieldsMap || props.isDisabled} //adding value is a workaround.
          helperText={schema.helperText}
        />
    );
    if (onSelect && onHoverIn && onHoverOut) {
      result = (
        <Interactions
          title={componentType}
          selected={!!selected}
          hovered={!!hovered}
          onSelect={onSelect}
          onHoverIn={onHoverIn}
          onHoverOut={onHoverOut}
        >
          {component}
        </Interactions>
      );
    } else {
      result = component;
    }
  } catch (err) {
    console.error(err);
  }

  if (schema.props?.block) {
    return (<Content width="100%">{result}</Content>)
  }

  if (schema.props?.breakLine) {
    return (
      <>
        {result}
        <div className={breakLineStyle}></div>
      </>)
  }

  return (result);
};

const breakLineStyle = style({
  width: "100%",
  height: '1px',
  marginBottom: '-27px !important'
})