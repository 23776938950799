import { Label } from "components/Label";
import { Content, Horizontal, Vertical } from "gls";
import React, { ReactElement, useEffect, useMemo, useState } from "react";
import ReactSelect, {
  ActionMeta,
  components,
  MultiValue,
  OptionProps,
  Options,
  SingleValue,
} from "react-select";
import { classes, style } from "typestyle";
import {
  selectCheckboxContainerStyles,
  selectHiddenStyle,
  selectStyles,
  selectTheme,
} from "./selectStyles";
import { KeyValuePair } from "utils/common";
import { colours } from "styling/colours";
import useDevice, { MIN_SELECT_MENU_HEIGHT } from "hooks/useDevice";
import { ComponentSchema } from "components/Renderer/Renderer";
import { checkboxStyle } from "../CheckBox/checkBoxStyles";

type SelectProps = {
  id?: string;
  label?: string | ReactElement;
  name?: string;
  value: any[];
  options: KeyValuePair<string>[];
  required?: boolean;
  className?: string;
  ariaLabel?: string;
  onChange: (v: any[]) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  width?: string;
  includeAll?: boolean;
  showAllText?: string;
  disabled?: boolean;
  readonly?: boolean;
  dataTestId?: string;
  isSearchable?: boolean;
  placeholder?: string;
  borderless?: boolean;
  innerLabel?: string; // show the label inside the input before value
  isClearable?: boolean; // hide the x button of clearing values
  hidden?: boolean;
  helperText?: string;
};

type OptionType = { label: string; value: string };

const CheckboxOption: React.FC<OptionProps<any, true>> = (props) => {
  const customStyle = props.isSelected
    ? (props.isDisabled ? { style: { color: 'black' } } : { style: { color: colours.secondary } })
    : {};

  if (props.isDisabled && !props.isSelected)
    return null;

  return (<components.Option {...props}>
    <Horizontal spacing={10}>
      <Content verticalAlign="center" className={checkboxStyle()}>
        {!props.isDisabled &&
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={(e) => null}
          />
        }
      </Content>
      <Content verticalAlign="center">
        <label {...customStyle}>{props.label}</label>
      </Content>
    </Horizontal>
  </components.Option>
  );
};

const renderSelectedText = (options: Options<OptionType>, allOptions: OptionType[], data: OptionType, showAllText?: string) => {

  if (options.filter(op => op.value !== "__all").length === 1) {
    return options.length === allOptions.length && showAllText
      ? showAllText
      : options.filter(op => op.value !== "__all")[0].label;
  }

  const all = options.length === allOptions.length;
  if (all && showAllText) {
    return showAllText;
  }
  return `${options.filter((option) => option.value !== "__all").length
    } selected`;
};

const MultiSelectCheckbox = (props: SelectProps) => {
  const [value, setValue] = useState<OptionType[]>([]);
  const [options, setOptions] = useState<OptionType[]>([]);
  const ariaLabel = props.ariaLabel || props.id || "Multi Select";
  const ref = React.useRef(null);
  const { isMobileDevice: isMobileWidth } = useDevice();

  const all = useMemo(
    () => props.options.map((option) => option.value),
    [props.options]
  );

  const handleChange = (
    v: MultiValue<OptionType> | SingleValue<OptionType>,
    actionMeta: ActionMeta<OptionType>
  ) => {
    if (actionMeta.option?.value === "__all") {
      props.onChange(actionMeta.action === "select-option" ? all : []);
      return;
    }

    const selectedValues: string[] = [];
    ((v as OptionType[]) || []).forEach((item) => {
      if (item.value !== "__all") {
        selectedValues.push(item.value);
      }
    });
    props.onChange(selectedValues);
  };

  const isFirst = (
    data: Options<OptionType>,
    currentSelectedOption: string
  ) => data[0].label === currentSelectedOption;

  const width = props.width ? props.width : "45%";
  const hidden = props.hidden ?? false;
  const placeHolder = props.disabled ? " " : props.placeholder || "Enter the Keyword";

  useEffect(() => {
    const allOption = props.includeAll
      ? props.options.length > 0
        ? [
          {
            label: "All",
            value: "__all",
          },
        ]
        : []
      : [];

    setOptions([
      ...allOption,
      ...(props.options || []).map((option) => ({
        label: option.key,
        value: option.value,
      })),
    ]);
  }, [props.includeAll, props.options]);

  useEffect(() => {
    const thisValue: OptionType[] = [];
    if (props.value && props.value.length > 0 && props.value.length === props.options.length) {
      thisValue.push({ label: "All", value: "__all" });
    }

    (props.value || []).forEach((val) => {
      const found = props.options.find((o) => o.value === val);

      if (found) {
        thisValue.push({ label: found.key, value: found.value });
      }
    });
    setValue(thisValue);
  }, [props.options, props.value]);
  return (
    <Vertical
      spacing={0}
      id={props.id || ariaLabel}
      className={classes(
        selectCheckboxContainerStyles(width),
        props.className,
        props.innerLabel ? innerLabelStyle(props.innerLabel) : "",
        selectHiddenStyle(hidden))}
      ref={ref}
      aria-label={ariaLabel}
      data-testid={props.dataTestId}
    >
      {props.label && (
        <Label
          htmlFor={props.name}
          value={props.label}
          required={props.required}
        />
      )}
      <ReactSelect
        id={props.id || ariaLabel || "multi-checkbox-select"}
        aria-label={`${ariaLabel} Input`}
        placeholder={placeHolder}
        isDisabled={props.disabled}
        isOptionDisabled={props.readonly ? () => true : () => false}
        isClearable={props.readonly ? false : props.isClearable}
        components={{
          Option: CheckboxOption,
          MultiValue: ({ children, ...innerProps }) => {
            return isFirst(innerProps.getValue(), innerProps.data.label) ? (
              <components.MultiValueContainer {...innerProps}>
                {renderSelectedText(
                  innerProps.getValue(),
                  options,
                  innerProps.data,
                  props.showAllText,
                )}
              </components.MultiValueContainer>
            ) : null;
          },
        }}
        isMulti
        menuPlacement="auto"
        menuPortalTarget={document.body}
        options={options}
        theme={selectTheme}
        styles={selectStyles(isMobileWidth, props.borderless)}
        value={value}
        onChange={handleChange}
        classNamePrefix="scrollable"
        closeMenuOnScroll={(e) =>
          (e.target as HTMLElement).className.indexOf("scrollable") === -1
        }
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        blurInputOnSelect={false}
        minMenuHeight={isMobileWidth ? undefined : MIN_SELECT_MENU_HEIGHT}
        data-testid={props.dataTestId}
        isSearchable={props.readonly ? false : props.isSearchable}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
      />
    </Vertical>
  );
};

export default MultiSelectCheckbox;

export const MultiSelectCheckboxOptions: ComponentSchema = {
  label: "MultiSelectCheckbox",
  type: "MultiSelectCheckbox",
  icon: "SelectIcon",
  propsSchema: [
    {
      id: "label",
      type: "Input",
      props: {
        label: "Label",
      },
    },
    {
      id: "placeholder",
      type: "Input",
      props: {
        label: "Placeholder",
      },
    },
    {
      id: "ariaLabel",
      type: "Input",
      props: {
        label: "Aria Label",
      },
    },
    {
      id: "options",
      type: "KeyValuePairs",
      props: {
        label: "Key/Value Pairs",
      },
    },
  ],
};

const innerLabelStyle = (label: string) => style({

  $nest: {
    "& .scrollable__control": {
      $nest: {
        "&::before": {
          content: `'${label}'`,
          paddingLeft: "16px",
          color: colours.secondaryText
        }
      }
    },

  }
})