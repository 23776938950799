/*
RoleListStore class handles all states, API calls,
  and logic of listing role records.
*/
import { DeleteRole, GetWorkgroupsAndRoles, UsersAffectedByRole, accessedWorkgroups } from "api/config/roles";
import { keys } from "lodash";
import { action, makeObservable, observable } from "mobx";
import { RootStore } from "stores/RootStore";
import { KeyValuePair } from "utils/common";
import { roleListItem } from "views/config/roles/RoleConfigDto";
import { WorkGroup } from "views/config/workgroups/WorkgroupDto";

export class RolesListStore {
  root: RootStore;
  version: string = "";
  listLoading = true;
  actionLoading = false;
  roles: Record<string, roleListItem[]> = {};
  workgroups: Record<string, WorkGroup> = {};
  accessedWorkgroups: KeyValuePair[] = [];
  dataLoadingError: boolean = false;
  selectedWorkgroup: string = "";
  associatedUsersLoading: boolean = false;
  associatedUsersNo: number = 0;
  deleteModalOpen = false;
  roleToDelete: roleListItem | null = null;

  constructor(root: RootStore) {
    this.root = root;

    makeObservable(this, {
      listLoading: observable,
      workgroups: observable,
      accessedWorkgroups: observable,
      actionLoading: observable,
      dataLoadingError: observable,
      roles: observable,
      selectedWorkgroup: observable,
      associatedUsersNo: observable,
      deleteModalOpen: observable,
      roleToDelete: observable,

      changeWorkgroup: action.bound,
      setListLoading: action.bound,
      LoadData: action.bound,
      deleteRole: action.bound,
      loadAssociatedUsers: action.bound,
      setDeleteModalOpen: action.bound,
      setRoleToDelete: action.bound,
      setActionLoading: action.bound,
      reset: action.bound,
    });
  }

  setDeleteModalOpen(value: boolean) { this.deleteModalOpen = value }
  setRoleToDelete(value: roleListItem | null) { this.roleToDelete = value }
  setActionLoading(value: boolean) { this.actionLoading = value }

  reset() {
    this.version = "";
    this.listLoading = true;
    this.actionLoading = false;
    this.roles = {};
    this.workgroups = {};
    this.accessedWorkgroups = [];
    this.dataLoadingError = false;
    this.selectedWorkgroup = "";
    this.associatedUsersLoading = false;
    this.associatedUsersNo = 0;
    this.deleteModalOpen = false;
    this.roleToDelete = null;
  }

  async loadAssociatedUsers(role: roleListItem) {
    try {
      const res = await UsersAffectedByRole(role.id);
      this.associatedUsersNo = res;
      return true;
    }
    catch (error) {
      return false;
    }
  }

  async deleteRole(role: roleListItem) {
    this.actionLoading = true;
    try {
      const res = await DeleteRole(role.id, this.version);

      this.roles[this.selectedWorkgroup] = this.roles[this.selectedWorkgroup].filter((roles) => roles.id !== role.id);
      this.version = res;
      return true;
    }
    catch (error) {
      return false;
    }
    finally {
      this.actionLoading = false;
    }
  }

  setListLoading(isLoading: boolean) {
    this.listLoading = isLoading;
  }

  changeWorkgroup(workgroupId: string) {
    this.selectedWorkgroup = workgroupId;
  }

  private setRoles(data: Record<string, roleListItem[]>) {
    for (const workgroup in data) {
      this.roles[workgroup] = data[workgroup].sort((x, y) => x.name.toLowerCase() > y.name.toLowerCase() ? 1 : -1);
    }
  }

  LoadData(selectedWorkgroup: string | undefined) {
    this.setListLoading(true);
    return Promise.all([
      GetWorkgroupsAndRoles(),
      accessedWorkgroups(),
    ])
      .then(res => {
        this.workgroups = res[0].workgroup_id_to_workgroups;
        this.setRoles(res[0].workgroup_id_to_roles);
        this.version = res[0].version;
        if (keys(this.workgroups).length > 0) {
          if (selectedWorkgroup) {
            const filter = keys(this.workgroups).filter(wg => this.workgroups[wg].id === selectedWorkgroup)
            this.selectedWorkgroup = filter.length > 0 ? filter[0] : keys(this.workgroups)[0];
          }
          else {
            this.selectedWorkgroup = keys(this.workgroups)[0];
          }
        }

        this.accessedWorkgroups = res[1].workgroups;

      })
      .catch(() => {
        this.dataLoadingError = true;
      })
      .finally(() => {
        this.setListLoading(false);
      })
  }

}
