import { Vertical } from "gls/lib";
import { uniqueId } from "lodash";
import React, { forwardRef } from "react";
import { classes, media, style } from "typestyle";
import { Error } from "../Error";
import { colours } from "styling/colours";
import { MOBILEDEVICEWIDTH } from "config/constants";

type BaseInputProps = {
  value: string | number;
  className?: string;
  id?: string;
  type?: string;
  name?: string;
  placeholder?: string;
  disabled?: boolean;
  errors?: string[];
  containerror?: boolean;
  onChange: (value: string) => void;
  onFocus?: (e?: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e?: React.FocusEvent<HTMLInputElement>) => void;
  onClick?: () => void;
  autoFocus?: boolean;
  dataTestId?: string;
  onKeyPress ?: (e?:React.KeyboardEvent<HTMLInputElement>) => void;
};

export const BaseInput = forwardRef<HTMLInputElement, BaseInputProps>(
  (props, ref) => {
    const {
      autoFocus,
      value,
      onChange,
      onFocus,
      onBlur,
      name = "input",
      dataTestId,
      containerror,
      onKeyPress,
      ...otherProps
    } = props;
    const border = props.disabled ? { style: { border: "0px" } } : {};

    const inputName = `${name}_${uniqueId(String(Date.now()))}`;
    const containsError = Boolean((props.errors && props.errors.length) || containerror === true);

    const handleChange = (evt: React.FormEvent<HTMLInputElement>) => {
      onChange(evt.currentTarget.value);
    };

    const handleFocus = (e?: React.FocusEvent<HTMLInputElement>) =>
      onFocus && onFocus(e);
    const handleBlur = (e?: React.FocusEvent<HTMLInputElement>) =>
      onBlur && onBlur(e);

    return (
      <Vertical spacing={0} width="100%">
        <Vertical
          width="100%"
          spacing={0}
          id={props.id}
          className={props.className}
        >
          <input
            {...otherProps}
            {...border}
            data-testid={dataTestId}
            name={inputName}
            ref={ref}
            className={classes(inputStyle, containsError && errorBorder)}
            value={value}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            autoComplete="no"
            autoFocus={autoFocus}
            onKeyPress={onKeyPress}
          />
        </Vertical>
        {props.errors &&
          props.errors.map((e, i) => (
            <Error
              key={`baseinput-err-${props.id}`}
              id={`baseinput-err-${props.id}`}
              message={e}
            />
          ))}
      </Vertical>
    );
  }
);

export const inputBaseStyle = {
  width: "100%",
  fontSize: "15px",
  height: 50,
  borderRadius: "3px",
  padding: "16px",
  border: `1px solid ${colours.tertiaryText}`,
  outline: 0,
};


export const inputStyle = style(
  {
    ...inputBaseStyle,
    $nest: {
      "&:disabled": {
        background: "#F1F1F1",
      },
      "&:focus": {
        border: `.1px solid ${colours.secondary}`,
      },
    },
  },
  media({ maxWidth: MOBILEDEVICEWIDTH }, { height: 40, fontSize: 13 })
);

export const errorBorder = style({
  border: `1px solid ${colours.error}`,
  $nest: {
    "&:focus": {
      border: `1px solid ${colours.error}`,
    },
  },
});
