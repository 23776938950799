import React, { useEffect, useRef } from "react";
import { style } from "typestyle";
import { colours } from "styling/colours";
import Button from "components/Button/Button";
import AntModalStyled from "./AntModalStyled";
import { StyledIconWrapper } from "views/BeaconManagementPage/components/Styled";
import { Content } from "gls";

type GenericModalProps = {
  isOpen: boolean;
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  actionName?: string;
  cancelName?: string;
  actionEnabled?: boolean;
  actionStyle?: "cancel" | "neutral";
  cancelStyle?: "cancel" | "neutral";
  icon: React.ReactNode;
  onClose?: () => void;
  onAction?: () => void;
  onCancel?: () => void;
  hideCloseButton?: boolean;
  maskColor?: string;
  loading?: boolean;
  children?: React.ReactNode;
};

export const GenericModal = ({
  icon,
  title,
  subtitle,
  actionName,
  cancelName,
  actionStyle,
  actionEnabled,
  isOpen,
  onClose,
  onCancel,
  onAction,
  hideCloseButton = false,
  loading,
  children,
}: GenericModalProps) => {

  const CancelButton = cancelName ?? "Cancel";
  const emptyFn = () => { }
  const actionButtonRef = useRef<HTMLButtonElement>(null);
  const cancelButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (actionButtonRef.current)
      actionButtonRef.current.focus();
    else
      cancelButtonRef.current?.focus();
  }, [isOpen])

  return (
    <AntModalStyled
      centered
      open={isOpen}
      onCancel={onCancel ?? onClose ?? emptyFn}
      footer={null}
      closable={!hideCloseButton}
    >
      <header>
        <StyledIconWrapper>
          {icon}
        </StyledIconWrapper>
        <h3 className={style({ color: colours.secondaryText })}>{title}</h3>
      </header>
      <Content padding={[20, 0]}>
        {subtitle && <span className={style({ color: colours.primaryText })}>{subtitle}</span>}
        {children}
      </Content>
      <footer>
        {CancelButton &&
          <Button
            ref={cancelButtonRef}
            id="modal-cancel-btn"
            disabled={loading}
            outline
            onClick={onCancel ?? onClose ?? emptyFn}>
            {CancelButton}
          </Button>
        }
        {actionName && (
          <Button
            ref={actionButtonRef}
            styling={actionStyle}
            onClick={onAction ?? emptyFn}
            disabled={actionEnabled}
            isLoading={loading}
            id="modal-actin-btn"
          >
            {actionName}
          </Button>
        )}
      </footer>
    </AntModalStyled>
  );
};
