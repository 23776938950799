export function leadingZeros(n: number, length = 4) {
  let s = n?.toString();
  while (s && s.length < length) s = "0" + s;

  return s;
}

export function stripLeadingZeros(n: string) {
  let returnValue = "";
  let s = n?.toString();
  for (let i = 0; i < s.length; i++) {
    if (s[i] !== "0") {
      returnValue += s[i];
    }
  }

  return returnValue;
}

export function formatNumericValue(value: number) {
  return value < 0
    ? ""
    : value > 99
    ? value.toString()
    : leadingZeros(value, 2);
}

export function formatNullableNumericValue(value: number | undefined) {
  return value === undefined ? "" : formatNumericValue(value || 0);
}
