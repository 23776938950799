import React from 'react';
import LoadingBars from './LoadingBars';
import styled from 'styled-components';

const StyledP = styled.p`
  margin-top: 30px;
`;
export default function LoadingScreen({ text = "loading..." }: { text?: string }) {
  return (
    <main className="u-flex-center">
      <div className="container">
        <LoadingBars />
        <StyledP>{text}</StyledP>
      </div>
    </main>
  );
}
