import { Label } from "components/Label";
import { Vertical } from "gls";
import { isArray, isEmpty } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import ReactSelect, { ActionMeta, MenuPlacement, MultiValue } from "react-select";
import { classes } from "typestyle";
import { Error } from "../Error";
import {
  selectContainerStyles,
  selectStyles,
  selectTheme,
} from "./selectStyles";
import { KeyValuePair } from "utils/common";
import useDevice, { MIN_SELECT_MENU_HEIGHT } from "hooks/useDevice";
import { ComponentSchema } from "components/Renderer/Renderer";

type SelectProps = {
  id?: string;
  label?: string;
  name?: string;
  value: any[];
  options: KeyValuePair<string>[];
  required?: boolean;
  className?: string;
  ariaLabel?: string;
  includeAll?: boolean;
  onChange: (v: any[]) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  width?: string;
  minWidth?: string;
  placeholder?: string;
  showPlaceholderOnDisabled?: boolean;
  menuPlacement?: MenuPlacement;
  autoGrow?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  isOptionDisabled?: (option: any) => boolean;
  dataTestId?: string;
  error?: string | string[];
  helperText?: string;
};

type OptionType = { label: string; value: string };

export const MultiSelect = (props: SelectProps) => {
  // Maintain same API as native <select />
  // This calculations can be removed if there's no need for having the same API as native <select />
  // let value: OptionType[] = [];



  /*const options = (props.options || []).map((option) => ({
    label: option.key,
    value: option.value,
  }));*/
  const ref = React.useRef(null);
  const ariaLabel = props.ariaLabel || props.id || "Multi Select";
  const errors = props.error ? (isArray(props.error) ? props.error : [props.error]) : null;
  const [options, setOptions] = useState<OptionType[]>([]);
  const { isMobileDevice: isMobileWidth } = useDevice();
  const [value, setValue] = useState<OptionType[]>([]);

  const all = useMemo(
    () => props.options?.map((option) => option.value) || [],
    [props.options]
  );

  const handleChange = (v: MultiValue<OptionType>, actionMeta: ActionMeta<OptionType>) => {
    if (actionMeta.option?.value === "__all") {
      props.onChange(actionMeta.action === "select-option" ? all : []);
      return;
    }

    const value = ((v as OptionType[]) || []).map((item) => item.value);
    props.onChange(value);
  };

  useEffect(() => {
    const allOption = props.includeAll
      ? props.options.length > 0
        ? [
          {
            label: "All",
            value: "__all",
          },
        ]
        : []
      : [];

    setOptions([
      ...allOption,
      ...(props.options || []).map((option) => ({
        label: option.key,
        value: option.value,
      })),
    ]);
  }, [props.includeAll, props.options]);

  useEffect(() => {
    const thisValue: OptionType[] = [];
    (props.value || []).forEach((val) => {
      const found = props.options?.find((o) => o.value === val);

      if (found) {
        thisValue.push({ label: found.key, value: found.value });
      }
    });
    setValue(thisValue);
  }, [props.options, props.value]);

  const buildIds = () => props.dataTestId ? `${ariaLabel} Input ${props.dataTestId || ''}` : `${ariaLabel} Input`
  const placeHolder = !props.isDisabled
    ? props.placeholder || "Enter the Keyword"
    : props.showPlaceholderOnDisabled
      ? props.placeholder
      : " ";
  const inputId = props.id
    ? `${props.id}-input`
    : props.dataTestId
      ? `${ariaLabel} Input ${props.dataTestId || ''}`
      : `${ariaLabel} Input`;

  return (
    <Vertical
      spacing={0}
      id={props.id || ariaLabel}
      className={classes(
        selectContainerStyles(props.width, isMobileWidth, props.autoGrow),
        props.className
      )}
      ref={ref}
      aria-label={ariaLabel}
      data-testid={props.dataTestId}
    >
      {props.label && (
        <Label
          htmlFor={props.name}
          value={props.label}
          required={props.required}
        />
      )}
      <ReactSelect
        aria-label={`${ariaLabel} Input`}
        id={buildIds()}
        inputId={inputId}
        components={props.isDisabled ? { DropdownIndicator: () => null, IndicatorSeparator: () => null } : {}}
        isMulti
        isDisabled={props.isDisabled}
        isOptionDisabled={props.isOptionDisabled}
        menuPlacement={props.menuPlacement ? props.menuPlacement : "auto"}
        menuPortalTarget={document.body}
        options={options}
        theme={selectTheme}
        styles={selectStyles(
          isMobileWidth,
          false,
          props.autoGrow,
          props.minWidth ? props.minWidth : props.width,
          !isEmpty(props.error)
        )}
        value={value}
        onChange={handleChange}
        classNamePrefix="scrollable"
        closeMenuOnScroll={(e) =>
          (e.target as HTMLElement).className.indexOf("scrollable") === -1
        }
        minMenuHeight={isMobileWidth ? undefined : MIN_SELECT_MENU_HEIGHT}
        placeholder={placeHolder}
        isLoading={props.isLoading}
        data-testid={props.dataTestId}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
      />
      {errors &&
        errors.map((e, i) => (
          <Error
            key={`MultiSelect-err-${buildIds()}`}
            id={`MultiSelect-err-${buildIds()}`}
            message={e}
          />
        ))}
    </Vertical>
  );
};

export const MultiSelectOptions: ComponentSchema = {
  label: "MultiSelect",
  type: "MultiSelect",
  icon: "SelectIcon",
  propsSchema: [
    {
      id: "label",
      type: "Input",
      props: {
        label: "Label",
      },
    },
    {
      id: "placeholder",
      type: "Input",
      props: {
        label: "Placeholder",
      },
    },
    {
      id: "ariaLabel",
      type: "Input",
      props: {
        label: "Aria Label",
      },
    },
    {
      id: "options",
      type: "KeyValuePairs",
      props: {
        label: "Key/Value Pairs",
      },
    },
  ],
};
