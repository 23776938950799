import { ChangeMap, PropsSchema } from "components/Renderer/Renderer";
import { isEmpty } from "lodash";

type ChangeMapFieldId = string;

export function generateModuleFieldsOnChangeMap(
  fields: PropsSchema[], 
  index: string,
  onChangeMap: undefined | Record<string, ChangeMap>, 
  hasValue: boolean): [ChangeMapFieldId, ChangeMap][] {
  
  return fields.map(field => generateFieldOnChangeMap(field, index, onChangeMap, hasValue));
}

export function generateFieldOnChangeMap(
  field: PropsSchema, 
  index: string,
  onChangeMap: undefined | Record<string, ChangeMap>, 
  hasValue: boolean): [ChangeMapFieldId, ChangeMap] {
    
  const isChanged = !!(onChangeMap && !isEmpty(onChangeMap) && onChangeMap[field.id]?.changed);

  return [field.id + index, {
    required: !!field.props?.required,
    changed: hasValue || isChanged,
  }];
}
