import { useCallback, useEffect, useState } from "react";
import XRegExp from "xregexp";

export type InputType = "text" | "number" | "password" | "tel" | "url" | "hidden" | "time";

const validation: Record<InputValidationType, Function> = {
  required: (inputType: string, value: string) => {
    if (!value.trim()) {
      return "This field is required.";
    }
  },
  number: (inputType: string, value: string) => {
    if (value.trim() && isNaN(+value) && inputType === "number") {
      return "Please enter only numbers.";
    }
  },
  max: (inputType: string, value: string, maxValue: number) => {
    const trimmedValue = value.trim();
    if (
      inputType === "number" &&
      !isNaN(+trimmedValue) &&
      +trimmedValue > maxValue
    ) {
      return `Maximum quantity allowed is ${maxValue}.`;
    }
  },
  min: (inputType: string, value: string, minValue: number) => {
    const trimmedValue = value.trim();
    if (
      inputType === "number" &&
      !isNaN(+trimmedValue) &&
      +trimmedValue < minValue
    ) {
      return `Minimum quantity allowed is ${minValue}.`;
    }
  },
  alphaNumeric: (inputType: string, value: string) => {
    if (value.trim() && inputType === "text") {
      const alphaNumericRegEx = XRegExp('^[\\p{Latin},0-9, ,.,",,,-]+$');
      if (!alphaNumericRegEx.test(value)) {
        return "Please enter letters, numbers spaces, '.', '\"', ',' and '-'.";
      }
    }
  },
  fileName: (inputType: string, value: string) => {
    if (value.trim() && inputType === "text") {
      // eslint-disable-next-line no-useless-escape
      if (!value.match(/^[\w\-\s\.]+$/)) {
        return "The characters that are allowed for the filter name are letters, numbers, ., -, and _'";
      }
    }
  },
  textOnly: (inputType: string, value: string) => {
    if (value.trim() && inputType === "text") {
      const alphaNumericRegEx = XRegExp("^[\\p{Latin}, ,-]+$");
      if (!alphaNumericRegEx.test(value)) {
        return "Please enter letters, spaces and '-' only";
      }
    }
  },
  tel: (inputType: string, value: string) => {
    if (value.trim() && inputType === "text") {
      // eslint-disable-next-line no-useless-escape
      const alphaNumericRegEx = new RegExp(/^([\d\+\-\(\)\\\/]{0,20})$/);
      if (!alphaNumericRegEx.test(value)) {
        return "Please enter + - ( ) / and up to 20 digits";
      }
    }
  },
  float: (inputType: string, value: string) => {
    const floatValue = parseFloat(value);
    if (value !== "" && (isNaN(floatValue) || value !== floatValue.toString())) {
      return "Please enter numbers, and decimal points, only.";
    }
  },
  int: (inputType: string, value: string) => {
    const intvalue = parseInt(value);
    if (value !== "" && (isNaN(intvalue) || value !== intvalue.toString())) {
      return "Please enter numbers only.";
    }
  },
};

export type InputValidationType =
  | "alphaNumeric"
  | "fileName"
  | "max"
  | "min"
  | "number"
  | "float"
  | "int"
  | "required"
  | "tel"
  | "textOnly";

export function useValidation(
  inputType: InputType,
  validationAttributes: Record<string, unknown>,
  errors: string | string[],
  isDirty?: boolean
) {
  const [dirty, setDirty] = useState(isDirty || false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (dirty) {
      setShowError(true);
    }
  }, [dirty, errors]);

  const pushError = (errors: string[], error: string) => {
    if (error) {
      errors.push(error);
    }
  };

  const validate = useCallback(
    (value: string) => {
      let errors: string[] = [];
      validationAttributes["required"] &&
        pushError(errors, validation["required"](inputType, value));
      if (inputType === "number") {
        "float" in validationAttributes
          ? (validationAttributes["float"]
            ? pushError(errors, validation["float"](inputType, value))
            : pushError(errors, validation["int"](inputType, value))
          )
          : pushError(errors, validation["number"](inputType, value))
      }
      validationAttributes["max"] &&
        pushError(
          errors,
          validation["max"](
            inputType,
            value,
            parseInt(validationAttributes["max"] as string)
          )
        );
      //because min can be 0 and thus falsey
      !isNaN(+(validationAttributes["min"] as string)) &&
        pushError(
          errors,
          validation["min"](
            inputType,
            value,
            parseInt(validationAttributes["min"] as string)
          )
        );
      validationAttributes["alphaNumeric"] &&
        pushError(errors, validation["alphaNumeric"](inputType, value));
      validationAttributes["textOnly"] &&
        pushError(errors, validation["textOnly"](inputType, value));
      validationAttributes["tel"] &&
        pushError(errors, validation["tel"](inputType, value));
      validationAttributes["fileName"] &&
        pushError(errors, validation["fileName"](inputType, value));

      setDirty(true);
      return errors;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validationAttributes]
  );

  return {
    validate,
    showError,
    setShowError
  };
}
