import { Content } from "gls/lib";
import React from "react";
import { colours } from "styling/colours";
import { classes, style } from "typestyle";
import { leadingZeros } from "utils/numeric";

type NumberOfTasksProps = {
  totalNumberOfItems: number;
  text: string;
  alternateText?: string;
  className?: string;
};

export const NumberOfItems = (props: NumberOfTasksProps) => {
  return (
    <Content style={{ paddingTop: 10 }}>
      <Content
        className={classes(wrapperStyle, props.className)}
      >{`# ${leadingZeros(props.totalNumberOfItems, 2)} ${props.text}`}</Content>
    </Content>
  );
};

const wrapperStyle = style(
  {
    fontFamily: "MuseoSlab",
    color: colours.secondaryText,
    fontSize: "14px",
    marginBottom: "30px",
  }
);
