import { icons } from "assets/icons";
import { Horizontal, Vertical } from "gls/lib";
import React from "react";
import { colours } from "styling/colours";
import { style } from "typestyle";

type ScrollButtonProps = {
  onClick?: () => void;
  right?: string;
  bottom ?: string;
};

export const ScrollButton = (props: ScrollButtonProps) => {

  const right = props.right || "65px";
  const bottom = props.bottom || "15px";
  return (
    <button onClick={props.onClick} className={scrollButtonStyle(right, bottom)}>
      <Vertical spacing={5}>
        <Horizontal horizontalAlign="center">
          <icons.TriangleUpIcon />
        </Horizontal>
        <span>Top</span>
      </Vertical>
    </button>
  );
};

const scrollButtonStyle = (right:string, bottom:string) => style({
  position: "fixed",
  right: right,
  bottom: bottom,
  height: "50px",
  width: "50px",
  borderRadius: "100%",
  backgroundColor: colours.secondary,
  outline: "none",
  border: "none",
  color: "white",
  cursor: "pointer",
  padding: "5px 0 10px 0",
  zIndex: 10,
});
