import React from "react";
import { Horizontal } from "gls/lib/components/horizontal";
import { Box, StretchSpacer, Vertical } from "gls/lib";
import { style, classes } from "typestyle";
import { icons } from "assets/icons";
import { colours } from "styling/colours";

type InteractionsProps = {
  children: React.ReactNode;
  title: string;
  selected: boolean;
  hovered: boolean;
  onSelect: () => void;
  onHoverIn: () => void;
  onHoverOut: () => void;
};

export const Interactions = (props: InteractionsProps) => {
  const { selected, hovered, children } = props;
  const [playable, setPlayable] = React.useState(false);
  let result = children;

  const togglePlay = (event: React.MouseEvent) => {
    event.stopPropagation();
    setPlayable(!playable);
  };

  const handleSelect = (event: React.MouseEvent) => {
    event.stopPropagation();
    props.onSelect();
  };

  if (selected || hovered) {
    result = (
      <>
        <Horizontal
          className={selectionStyle}
          horizontalAlign="left"
          spacing={0}
        >
          <Box className={tooltipStyle}>{props.title}</Box>
          <StretchSpacer />
          {selected && (
            <Horizontal spacing={4} className={tooltipStyle}>
              <button className={iconsStyle} onClick={togglePlay}>
                {!playable ? (
                  <icons.PlayIcon height={16} width={16} />
                ) : (
                  <icons.PauseIcon height={16} width={16} />
                )}
              </button>
              <button className={iconsStyle} onClick={() => {}}>
                <icons.DeleteIcon height={16} width={16} />
              </button>
            </Horizontal>
          )}
        </Horizontal>
        {props.children}
      </>
    );
  }

  return (
    <Vertical
      className={classes(
        itemStyle,
        (props.selected || props.hovered) && itemHoveredStyle
      )}
      spacing={0}
      onMouseOver={props.onHoverIn}
      onMouseLeave={props.onHoverOut}
      onClick={handleSelect}
    >
      {result}
      {!playable && <div className={itemOverlayStyle}></div>}
    </Vertical>
  );
};

const itemStyle = style({
  width: "100%",
  position: "relative",
  border: "1px solid transparent",
  boxSizing: "border-box",
});

const itemHoveredStyle = style({
  border: `1px solid ${colours.secondary}`,
});

const itemOverlayStyle = style({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: "transparent",
  zIndex: 10,
});

const selectionStyle = style({
  position: "absolute",
  height: "24px",
  top: "-24px",
  width: "100%",
});

const tooltipStyle = style({
  background: colours.secondary,
  display: "inline-block",
  color: "white",
  padding: "0 4px",
  fontSize: "13px",
  lineHeight: "24px",
});

const iconsStyle = style({
  background: "transparent",
  width: "20px",
  height: "24px",
  padding: 0,
  border: 0,
  cursor: "pointer",
  outline: "none",
  zIndex: 11,
  $nest: {
    svg: {
      position: "relative",
      top: "1px",
      left: 0,
    },
    "svg g": {
      stroke: "white",
    },
  },
});
