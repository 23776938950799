import { isEmpty } from "lodash";
import { StylesConfig, Theme } from "react-select";
import { colours } from "styling/colours";
import { style } from "typestyle";

export const selectContainerStyles = (
  width?: string,
  isMobileWidth?: boolean,
  autoGrow?: boolean
) =>
  style({
    width: autoGrow ? "100%" : width ? width : "100%",
    maxWidth: width
      ? isMobileWidth
        ? "80vw"
        : undefined
      : autoGrow
        ? "100%"
        : "300px",
    overflowY: "initial",
  });

export const selectHiddenStyle = (hidden: boolean) => style({
  visibility: hidden ? "hidden" : "visible"
})

export const selectCheckboxContainerStyles = (width?: string | number) =>
  style({
    width: width || "300px",
    overflowY: "initial",
  });

export const selectTheme = (theme: Theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: colours.secondary,
    primary25: "rgba(188, 190, 192, 0.15)",
  },
});

export const selectStyles = (
  isMobileWidth?: boolean,
  borderless?: boolean,
  autoGrow?: boolean,
  minWidth?: string,
  error?: boolean
): StylesConfig<any, any> => ({
  container: (provided, state) => {
    return {
      ...provided,
      color: colours.primaryText,
      fontSize: isMobileWidth ? "13px" : "15px",
      border: "none",
      backgroundColor: state.isDisabled
        ? colours.disableSelect
        : borderless
          ? undefined
          : colours.white,
      minWidth: autoGrow
        ? minWidth
        : provided.width,
      width: autoGrow ? "fit-content" : provided.width,
    };
  },
  control: (provided, state) => ({
    ...provided,
    outline: 0,
    minHeight: borderless
      ? "40px"
      : isMobileWidth
        ? "40px"
        : "50px",
    borderColor: error ? colours.error : colours.tertiaryText,
    borderWidth: "1px",
    boxShadow: "none",
    border:
      borderless
        ? "none"
        : state.isDisabled && !state.isMulti
          ? "none"
          : provided.border,
    backgroundColor: state.isDisabled
      ? (state.isMulti
        ? (state.selectProps.value && state.selectProps.value.length > 0
          ? colours.white
          : undefined)
        : colours.disableSelect)
      : undefined,
  }),
  input: (provided) => ({
    ...provided,
    outline: 0,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: borderless ? 0 : "0 16px",
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 10000,
    // top: calc(`${base.top}px - 10px`),
    marginBottom: "1em",
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 10000,
    // padding: "4px 7px",
    borderColor: colours.border,
    minWidth: provided.width,
    width: borderless ? "max-content" : provided.width,
    marginTop: borderless ? "4px" : provided.marginTop,
  }),
  menuList: (provided, state) => ({
    ...provided,
    zIndex: 10000,
    minWidth: borderless ? "max-content" : provided.width,
  }),
  group: () => ({
    paddingTop: 0
  }),
  groupHeading: (base, props: any) => {
    if (isEmpty(props.data.label)) {
      if (props.data.index === 0)
        return ({
          display: "none"
        })
      return ({
        background: "#CFCFCF",
        width: "100%",
        height: "1px",
        margin: "10px 0 10px 0",
      })
    }
    return ({
      background: colours.panelBackground,
      padding: '5px 15px',
      color: colours.primaryText,
      fontWeight: 'bold',
      fontSize: '12px',
      textTransform: 'uppercase'
    })
  },
  option: (provided, { data, isDisabled, isFocused, isSelected }) => ({
    ...provided,
    padding: "11px 9px",
    borderRadius: "3px",
    width: 'calc(100% - 14px)',
    margin: '0 7px',
    background: isFocused ? "rgba(188, 190, 192, 0.15)" : colours.white,
    color: isDisabled
      ? "#ccc"
      : isSelected
        ? colours.primary
        : isFocused
          ? colours.secondary
          : data.color,
    ":active": {},
  }),
  singleValue: (provided, state) => {
    const isBorderless = borderless;

    return {
      ...provided,
      color: colours.primaryText,
      position: isBorderless ? "unset" : provided.position,
      transform: isBorderless ? "unset" : provided.transform,
      overflow: isBorderless ? "unset" : provided.overflow,
    };
  },
  multiValue: (provided, { isDisabled }) => ({
    ...provided,
    height: "26px",
    padding: "2px",
    color: "#00b259",
    background: isDisabled ? "#F1F1F1" : "rgba(0, 178, 89, 0.1)",
    marginRight: isDisabled ? "8px" : "2px",
  }),
  multiValueLabel: (provided, { isDisabled }) => ({
    ...provided,
    color: isDisabled ? "#414042" : "#00b259",
    paddingRight: isDisabled ? "8px" : "0px",
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    display: state.selectProps.isDisabled ? "none" : "inherit",
    color: "#00b259",
    ":hover": {
      cursor: "pointer",
      background: "rgba(0, 178, 89, 0.1)",
      color: "#00b259",
    },
  }),
  indicatorSeparator: () => ({}),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: colours.primaryText,
  }),
});

export const dropIconStyle = (isDisabled?: boolean) =>
  isDisabled
    ? style({
      $nest: {
        svg: {
          display: "none",
        },
      },
    })
    : "";
