import split from "graphemesplit";

export class GraphemeString {
  private charArray: string[];

  constructor(text: string) {
    // Use of split from library could be replaced by Intl.Segmenter
    this.charArray = split(text);
  }

  public get length(): number {
    return this.charArray.length;
  }

  slice(start: number, end: number): string {
    return this.charArray.slice(start, end).join("");
  }

  toString(): string {
    return this.charArray.join("");
  }
}
