import { Vertical } from "gls/lib";
import { isArray } from "lodash";
import React from "react";
import { Label } from "../../Label";
import { useValidation } from "hooks/useValidation";
import { BaseInput } from "../Input/BaseInput";
import { ComponentSchema } from "components/Renderer/Renderer";

export type InputNumberProps = {
  id?: string;
  ariaLabel?: string;
  disabled?: boolean;
  error?: string | string[];
  label?: string | React.ReactElement;
  max?: number;
  min?: number;
  name: string;
  onChange: (value: number | string | undefined, errs?: string[]) => void;
  placeholder?: string;
  required?: boolean;
  value: number | undefined;
  dataTestId?: string;
  helperText?: string;
  width?: string;
  maxWidth?: string;
  inputClassName?: string;
  labelClassName?: string;
  hideErrorText?: boolean;
  isFloat?: boolean;
};

export const InputNumberDefaultProps = {
  label: "Input Number",
  placeholder: "numbers only",
  min: 0,
};

export function InputNumber(props: InputNumberProps) {
  const {
    ariaLabel = "Number Input",
    id = ariaLabel,
    disabled,
    error,
    label,
    name,
    onChange,
    placeholder,
    value,
    labelClassName,
    width = "100%",
    maxWidth = "300px",
    hideErrorText = false,
    isFloat = true,
    ...rest
  } = props;
  const { validate } = useValidation(
    "number",
    {
      float: isFloat,
      ...rest,
    },
    error || ""
  );

  const handleChange = (handleChangeValue: string) => {
    const errors = validate(handleChangeValue);

    onChange &&
      onChange(errors.length > 0
        ? handleChangeValue
        : handleChangeValue
          ? parseFloat(handleChangeValue)
          : "",
        errors);
  };
  const errors = error ? (isArray(error) ? error : [error]) : [];

  return (
    <Vertical
      spacing={0}
      width={width}
      maxWidth={maxWidth}
      aria-label={ariaLabel}
      id={id || ariaLabel}
    >
      {label && (
        <Label htmlFor={name} value={label} required={props.required} className={labelClassName} />
      )}
      <BaseInput
        value={value ?? ""}
        onChange={handleChange}
        name={name}
        disabled={disabled}
        placeholder={placeholder}
        onKeyPress={(e) => {
          const pattern = /[0-9]|\.|-/
          if (!pattern.test(e?.key || "")) {
            e?.preventDefault();
          }
        }}
        errors={hideErrorText ? [] : errors}
        aria-label={ariaLabel}
        id={`${id} Input`}
        dataTestId={props.dataTestId}
        className={props.inputClassName}
        containerror={hideErrorText ? errors.length > 0 : false}
      />
    </Vertical>
  );
}

export const InputNumberOptions: ComponentSchema = {
  label: "Number",
  type: "InputNumber",
  icon: "InputNumberIcon",
  propsSchema: [
    {
      id: "label",
      type: "Input",
      props: {
        label: "Label",
      },
    },
    {
      id: "placeholder",
      type: "Input",
      props: {
        label: "Placeholder",
      },
    },
    {
      id: "ariaLabel",
      type: "Input",
      props: {
        label: "Aria Label",
      },
    },
    {
      id: "alignment",
      type: "Select",
      defaultValue: "vertical",
      props: {
        label: "alignment",
      },
    },
  ],
  validationSchema: [
    {
      id: "required",
      type: "Toggle",
      props: {
        label: "Required",
        width: "100%"
      },
    },
    {
      id: "min",
      type: "InputNumber",
      props: {
        label: "Minimum Value",
        placeholder: "0-99999999",
      },
    },
    {
      id: "max",
      type: "InputNumber",
      props: {
        label: "Maximum Value",
        placeholder: "0-99999999",
      },
    },
  ],
};