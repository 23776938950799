import { createContext, ReactNode, useContext } from "react";
import { RootStore } from "../stores/RootStore";
import { AuthenticationContext } from "./authentication.context";
import { getIdToken } from "utils/utils";

type RootStoreProviderProps = {
  children: ReactNode;
};

let store: RootStore;
const StoreContext = createContext<RootStore | undefined>(undefined);

export function useRootStore() {
  const context = useContext(StoreContext);
  if (context === undefined) {
    throw new Error("useRootStore must be used within RootStoreProvider");
  }

  return context;
}

export function useSystemAdminPermissionStore() {
  const { systemAdminPermissionStore } = useRootStore();
  return systemAdminPermissionStore;
}

export function useUserManagementStore() {
  const { userStore } = useRootStore();
  return userStore;
}

export function useWorkgroupsStore() {
  const { workgroupsStore } = useRootStore();
  return workgroupsStore;
}

export function useRolesListStore() {
  const { rolesListStore } = useRootStore();
  return rolesListStore;
}

export function useRoleStore() {
  const { roleStore } = useRootStore();
  return roleStore;
}

export function useSystemAdministratorsStore() {
  const { systemAdministratorsStore } = useRootStore();
  return systemAdministratorsStore;
}

export function useDataTableStore() {
  const { dataTableStore } = useRootStore();
  return dataTableStore;
}

export function RootStoreProvider({ children }: RootStoreProviderProps) {

  const authContext = useContext(AuthenticationContext).authState;
  const token = getIdToken(authContext);
  RootStore.authToken = token;
  // only create root store once (store is a singleton)
  const root = store || new RootStore();
  return <StoreContext.Provider value={root}>{children}</StoreContext.Provider>;
}