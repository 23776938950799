import React from "react";
import ReactDOM from "react-dom";

type PortalProps = {
  children: React.ReactNode;
  target?: HTMLElement | null;
};

export const Portal = (props: PortalProps) => {
  const rootElement: HTMLElement =
    document.getElementById("root") || document.body;
  const target = props.target || rootElement;

  return ReactDOM.createPortal(props.children, target);
};
