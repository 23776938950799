import React from "react";
import { style } from "typestyle";

type RoundedIconProps = {
  children: React.ReactNode;
  fillColour?: string;
  bgColour?: string;
  size?: "large" | "medium" | "small";
};

export const RoundedIcon = (props: RoundedIconProps) => {
  return (
    <div className={iconStyle(props.fillColour, props.size ?? "large", props.bgColour)}>
      {props.children}
    </div>
  );
};

const iconStyle = (fillColour?: string, size?: string, bgColour?: string) => {
  const computedFillColour = `1px solid ${fillColour || "#e95637"}`;

  return style({
    border: computedFillColour,
    borderRadius: "100%",
    width: size === "large" ? 40 : size === "medium" ? 30 : 20,
    height: size === "large" ? 40 : size === "medium" ? 30 : 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: bgColour || 'auto'
  });
};
