import styled from 'styled-components';
import { colours } from 'styling/colours';

export const StyledTop = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  background-color: ${colours.white};
  width: 100%;
  height: 55px;

  .nav-container {
    padding: 10px;
  }
  .nav-search {
    width: 46px;
  }
`;

export const StyledMobileMenu = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  .container {
    width: 100%;
    height: 100vh;
    position: relative;
  }
  .nav-close-button {
    position: absolute;
    right: 10px;
    top: 20px;
  }
  .nav-userinfo {
    padding: 40px;
  }
  .nav-seperator {
    width: 100%;
    height: 10px;
    border-top: 1px solid ${colours.border};
  }
`;

export const StyledAside = styled.aside`
  position: fixed;
  max-width: 260px;
  width: 100%;
  height: 100vh;
  background-color: ${colours.white};
  left: 0;
  top: 0;
  box-shadow: -2px 1px 5px 0px rgba(0, 0, 0, 0.29);
  transition: width ease 0.2s;

  .nav-container {
    height: 100%;
    position: relative;
    display: flex;
    @media (max-height: 400px) {
      overflow-y: auto;
    }
    flex-direction: column;
    > .logo-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      img {
        margin: 35px 0 56px 44px;
      }
    }
  }

  button.nav-collapse-button {
    border: 1px solid #e6e6e6;
    border-width: 0 1px 0 0;
  }

  &.--collapsed {
    width: 105px;
    transition: width ease 0.1s;

    button.nav-collapse-button {
      right: -19px;
      transform: rotate(180deg);
      border-width: 0 0 0 1px;
    }
    .nav-container {
      > a {
        img {
          margin: 10px auto 0 auto;
        }
      }
      > .logo-container {
        img {
          margin-left: 25px;
        }
      }
    }
    nav {
      li {
        button {
          justify-content: center;
        }
        svg {
          margin: 0px 10px 0 0;
        }
      }
    }
  }
`;

export const StyledNav = styled.nav`
  width: 100%;
  padding: 0px;
  margin-top: 20px;
  ul.nav-menu-list {
    list-style: none;
    padding-left: 0;
  }
`;
export const StyledNavLinkItem = styled.li`
  position: relative;
  list-style: none;
  margin: 15px 0;
  height: 34px;

  svg {
    margin: 0px 10px 0 44px;
    height: 16px;
    width: 16px;
    min-width: 16px;
  }
`;
export const StyledNavLinkWithSubItem = styled.li`
  height: initial;
  width: 100%;
  margin: 15px 0;
  svg {
    margin: 0px 10px 0 44px;
    height: 16px;
    width: 16px;
    min-width: 16px;
  }
  ul.submenu-parent {
    list-style: none;
    padding-left: 70px;
    overflow: hidden;
    li {
      display: flex;
      align-items: center;
      height: 34px;
      margin: 15px 0;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const StyledNavMenuButton = styled.button`
  background: none;
  border: none;
  display: flex;
  align-items: center;
  padding: 7px 0;
  width: 100%;
  max-width: 250px;
  color: ${colours.primaryText};
  text-decoration: none;
  font-size: 15px;
  white-space: nowrap;
  font-family: MuseoSans;
  position: relative;
  transition: 0.1s color ease-in-out;

  img.arrow {
    position: absolute;
    right: 10px;
  }
  span {
    display: flex;
  }

  &.selected {
    color: ${colours.highlight};
    span {
      svg {
        fill: ${colours.highlight};
      }
    }
  }
  span {
    &.--highlight {
      svg {
        fill: ${colours.highlight};
      }
    }
    svg {
      fill: rgb(65, 64, 66);
      transition: 0.2s color ease-in-out;
    }
  }
  &:hover {
    color: #00aeef;
    cursor: pointer;
    transition: 0.2s color ease-in-out;
    span {
      svg {
        fill: #00aeef;
        transition: 0.2s fill ease-in-out;
      }
    }
  }
`;

export const Indicator = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 3px;
  background: ${colours.highlight};
  margin-right: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
`;
