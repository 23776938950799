import { Grid } from "gls/lib";
import { RendererComponent } from "components/Renderer/RendererComponent";
import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { useDeepCompareEffect } from "use-deep-compare";
import useDevice from "hooks/useDevice";
import { generateModuleFieldsOnChangeMap } from "./generateOnChangeMap";

export type CompositeSchema = {
  id: string;
  type: string;
  role: string;
};

export type PropsSchema = {
  id: string;
  ref?: "element_value" | "element_library" | "reference";
  tag?: string;
  type: string;
  props?: Record<string, any>;
  composite?: CompositeSchema;
  filtering?: string;
  category?: string;
  sensitivity?: string;
  defaultValue?: string | boolean;
  helperText?: string;
  identifier ?: boolean;
  skills?: string[][];
};

export type ComponentSchema = {
  label: string;
  type: string;
  icon: string;
  propsSchema: PropsSchema[];
  validationSchema?: PropsSchema[];
  styleSchema?: PropsSchema[];
  hasOptions?: boolean;
};

export type ChangeMap = {
  required: boolean;
  changed: boolean;
};

type RendererProps = {
  containerProps?: Record<string, unknown>;
  schema: PropsSchema[];
  values?: Record<string, unknown>;
  errors?: Record<string, string[]>;
  onChangeMap?: Record<string, ChangeMap>;
  className?: string;
  onChange?: (
    values: Record<string, unknown>,
    errors?: Record<string, string[]>,
    onChangeMap?: Record<string, ChangeMap>,
    changeKey?: string
  ) => void;
  selected?: string;
  hovered?: string;
  onSelect?: (selection: string) => void;
  onHoverIn?: (hovered: string) => void;
  onHoverOut?: () => void;
  onReorder?: (newOrder: PropsSchema[]) => void;
  onBlur?: () => void;
  index?: number;
  showInitialValidation?: boolean;
  disabledFields?: Record<string, boolean>;
};

export const Renderer = (props: RendererProps) => {
  const { values = {}, errors = {}, onChange } = props;
  const { isMobileDevice : isMobileWidth } = useDevice();
  const [schema, setSchema] = useState<PropsSchema[]>(props.schema);

  const updateSchema = (schemas: PropsSchema[]) => {
    setSchema(schemas);

    const updatedOnChangeMap: Record<string, ChangeMap> = {
      ...props.onChangeMap,
      ...Object.fromEntries(generateModuleFieldsOnChangeMap(schemas, props.index?.toString() || "", props.onChangeMap, false))
    };

    onChange && onChange(values, errors, updatedOnChangeMap);
  }

  useEffect(() => {
    for (const element of schema) {
      if (values[element.id] === null || values[element.id] === undefined) {
        if (element.type === "StepperControl") {
          values[element.id] = element.props?.min ?? 0;
        }
        else if (element.props?.defaultValue) {
          values[element.id] = element.props.defaultValue;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useDeepCompareEffect(() => {
    updateSchema(props.schema);
  }, [props.schema, values])

  const handleChange = (id: string, value: unknown, errs: string[] = []) => {
    const index: string = props.index?.toString() || "";

    const newValues = {
      ...values,
      [id]: value
    }

    const newErrors = {
      ...errors,
      [id + index]: errs,
    };

    onChange && onChange(newValues, newErrors, props.onChangeMap, id);

  };

  if (isEmpty(schema)) {
    return <></>
  }

  return (
    <Grid
      spacing={[27, 20]}
      justify="left"
      padding={{
        top: 0,
        left: isMobileWidth ? 13 : 27,
        right: isMobileWidth ? 32 : 47, // spacing makes component lose parent's width
        bottom: 0,
      }}
      className={props.className}
      autoComplete="off"
    >
      {(schema || []).map((c: PropsSchema, elementIndex: number) => (
        <RendererComponent
          key={`option-${c.id}`}
          schema={c}
          value={values && values[c.id]}
          error={errors && errors[c.id + (props.index?.toString() || "")]}
          onChange={handleChange}
          selected={props.selected ? props.selected === c.id : false}
          hovered={props.hovered ? props.hovered === c.id : false}
          onSelect={() => props.onSelect && props.onSelect(c.id)}
          onHoverIn={() => props.onHoverIn && props.onHoverIn(c.id)}
          onHoverOut={props.onHoverOut}
          onBlur={props.onBlur}
          index={props.index}
          elementIndex={elementIndex}
          dirty={props.showInitialValidation}
          disabledFields={props.disabledFields}
        />
      ))}
    </Grid>
  );
};
