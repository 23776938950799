import { DataTableStore } from "./DataTableStore";
import { SystemAdminPermissionStore } from "./SystemAdminPermissionStore";
import { RolesListStore } from "./config/RoleListStore";
import { RoleStore } from "./config/RoleStore";
import { SystemAdministratorsStore } from "./config/SystemAdministratorsStore";
import { UsersStore } from "./config/UsersStore";
import { WorkgroupsStore } from "./config/WorkgroupsStore";

export class RootStore {
  systemAdminPermissionStore: SystemAdminPermissionStore;

  workgroupsStore: WorkgroupsStore;
  rolesListStore: RolesListStore;
  roleStore: RoleStore;
  systemAdministratorsStore: SystemAdministratorsStore;
  dataTableStore: DataTableStore;
  userStore: UsersStore;

  static authToken: string;

  constructor() {
    this.systemAdminPermissionStore = new SystemAdminPermissionStore(this);

    this.workgroupsStore = new WorkgroupsStore(this);
    this.rolesListStore = new RolesListStore(this);
    this.roleStore = new RoleStore(this);
    this.systemAdministratorsStore = new SystemAdministratorsStore(this);
    this.userStore = new UsersStore(this);

    this.dataTableStore = new DataTableStore(this);
  }
}
