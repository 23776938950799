import { ComponentSchema } from "components/Renderer/Renderer";
import { Content, Horizontal, StretchSpacer, Vertical } from "gls/lib";
import React from "react";
import { style, stylesheet } from "typestyle";

export type ToggleProps = {
  id: string;
  value: boolean;
  label?: string;
  name: string;
  options?: { value: boolean };
  onChange: (v: boolean) => void;
  ariaLabel?: string;
  className?: string;
  disabled?: boolean;
  helperText?: string;
  labelPlace?: "right" | "left"; 
};

export const ToggleOptions: ComponentSchema = {
  label: "Toggle",
  type: "Toggle",
  icon: "ToggleIcon",
  propsSchema: [
    {
      id: "label",
      type: "Input",
      props: {
        label: "Label",
      },
    },
    {
      id: "ariaLabel",
      type: "Input",
      props: {
        label: "Aria Label",
      },
    },
  ],
};

export const ToggleDefaultProps = {
  label: "Toggle",
};

export const Toggle: React.FC<ToggleProps> = ({
  id,
  label,
  value,
  name,
  onChange,
  className,
  disabled,
  ariaLabel = "Toggle",
  helperText,
  labelPlace = "left"
}) => {
  return (
    <Vertical>
      <Horizontal
        verticalAlign="center"
        padding={{ top: 5, bottom: 5 }}
        className={className}
        aria-label={ariaLabel}
        id={ariaLabel}
      >
        {labelPlace === "left" && label && (
          <>
            <label htmlFor={id}>{label}</label>
            <StretchSpacer />
          </>
        )}
        <Content verticalAlign="center" className={styles.switch}>
          <input
            type="checkbox"
            id={id}
            data-testid={id}
            name={name}
            hidden
            checked={!!value}
            readOnly
            aria-label={`${ariaLabel} Input`}
            className={`${ariaLabel} Input`}
            disabled={disabled}
          />
          <span
            onClick={() => {
              if (!disabled) onChange(!value);
            }}
            className={slider(!!disabled, value)}
          ></span>
        </Content>
        {labelPlace === "right" && label && (
          <>
            <label htmlFor={id}>{label}</label>
            <StretchSpacer />
          </>
        )}
      </Horizontal>
    </Vertical>
  );
};

const styles = stylesheet({
  switch: {
    position: "relative",
    display: "inline-block",
    height: "20px",
    width: "31px",
  },
});

const slider = (disabled: boolean, value: boolean) =>
  style({
    display: "grid",
    cursor: disabled ? undefined : "pointer",
    height: "20px",
    width: "33px",
    backgroundColor: disabled ? "#dcdcdc" : "#ccc",
    transition: ".5s",
    borderRadius: "38px",
    $nest: {
      "&::before": {
        justifyContent: "flex-between",
        borderRadius: "50%",
        position: "absolute",
        content: '""',
        height: "14px",
        width: "14px",
        left: value ? "auto" : "3px",
        right: value ? "1px" : "auto",
        top: "3px",
        padding: "1px",
        backgroundColor: value
          ? disabled
            ? "#f3fcff"
            : "white"
          : disabled
            ? "#c9c9c9"
            : "#808285",
        transition: ".2s",
        zIndex: 0,
      },
      "input:checked + &": {
        backgroundColor: value
          ? disabled
            ? "#91dbf6"
            : "#00aeef"
          : disabled
            ? "#e6e6e6"
            : "#dcdcdc",
      },
    },
  });
