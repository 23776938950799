import { icons } from "assets/icons";
import { isEmpty, random } from "lodash";
import ReactTooltip, { Place, Type } from "react-tooltip";
import { colours } from "styling/colours";
import { style } from "typestyle";
import { Portal } from "./Portal";

type TooltipProps = {
  children: React.ReactNode;
  content?: JSX.Element | string;
  contentClassName?: string;
  infoIconContent?: boolean;
  type?: Type,
  place?: Place,
  maxWidth?: string;
};


export const Tooltip = ({ children, content, infoIconContent, type, place, contentClassName, maxWidth }: TooltipProps) => {

  const mainContent = content || (infoIconContent ? <icons.InfoIcon fill={colours.secondary} className={infoIconStyle} /> : false) || "";
  const bgColor = (type || "dark") === "dark" ? colours.primaryText : "";
  const id = `tooltip-${random(1, 9999999999)}`;
  return (
    <>
      <div
        data-for={id}
        className={contentClassName}
        /* data-event='click' data-event-off='dblclick'*/
        data-tip
      >
        {mainContent}
      </div>
      {!isEmpty(children) &&
        <Portal>
          <ReactTooltip
            id={id}
            effect='solid'
            backgroundColor={bgColor}
            place={place || "top"}
            type={type || "dark"}
            className={tooltipStyle(type || "dark", maxWidth)}
          >
            {children}
          </ReactTooltip>
        </Portal>
      }
    </>
  )
}

const tooltipStyle = (type: Type, maxWidth?: string) => style({
  borderRadius: '6px !important',
  fontSize: "15px !important",
  padding: "14px !important",
  opacity: "100% !important",
  zIndex: "999 !important",
  lineHeight: "22px !important",
  maxWidth: maxWidth || "auto"
})

const infoIconStyle = style({
  cursor: "pointer",
  position: "relative",
});