import React from "react";
import { Content } from "gls/lib";
import { classes, style } from "typestyle";
import { colours } from "styling/colours";

export enum Shape {
  round,
  squareish,
}


type BullpointCircleProps = {
  value: any;
  size?: number;
  colour?: string;
  background?: string;
  className?: string;
  shape?: Shape;
};

export const BullpointCircle = (props: BullpointCircleProps) => {
  return (
    <Content
      verticalAlign="center"
      horizontalAlign="center"
      className={classes(
        bullpointCircleStyle(
          props.size,
          props.colour,
          props.background,
          props.shape
        ),
        props.className
      )}
    >
      {props.value}
    </Content>
  );
};

const bullpointCircleStyle = (
  size = 35,
  color = colours.secondaryText,
  background = colours.background,
  shape = Shape.round
) =>
  style({
    background,
    color,
    minWidth: size,
    width: shape === Shape.round ? size : "auto",
    height: size,
    borderRadius: shape === Shape.round ? size : "8px",
    padding: shape === Shape.round ? "auto" : "3px 6px",
  });
