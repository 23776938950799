import { icons } from "assets/icons";
import { Input } from "components/inputs/Input/Input";
import { GenericModal } from "components/Modals/GenericModal";
import { useWorkgroupsStore } from "contexts/RootStore.context";
import { Content } from "gls/lib";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { colours } from "styling/colours";
import { stylesheet } from "typestyle";

export type EditWorkgroupNameModalProps = {
  isOpen: boolean;
  onClose: () => void;
  defaultValue: string;
  onSubmit: (workgroupName: string) => void;
  loading?: boolean;
};

export const EditWorkgroupNameModal = observer(
  ({
    defaultValue,
    isOpen,
    onClose,
    onSubmit,
    loading,
  }: EditWorkgroupNameModalProps) => {

    const [value, setValue] = useState("");
    const [error, setError] = useState("");
    const { workgroups, currentWorkgroup } = useWorkgroupsStore();

    const handleChange = (value: string) => {
      if (workgroups.some(w => w.name.toLowerCase() === value.toLowerCase() && w.id !== currentWorkgroup?.id)) {
        setError("A workgroup with the name specified already exists.")
      }
      else {
        setError("");
      }
      setValue(value)
    }

    useEffect(() => setError(""), [currentWorkgroup])
    useEffect(() => setValue(defaultValue), [defaultValue]);

    return (
      <GenericModal
        isOpen={isOpen}
        onCancel={onClose}
        loading={loading}
        onAction={() => onSubmit(value)}
        actionName="Save"
        icon={<icons.EditIconOutlined fill={colours.secondaryText} />}
        title="Edit Workgroup Name"
        actionEnabled={!value.trim() || value.trim() === defaultValue || error !== ""}
      >
        <Content className={ModalStyles.bodyWrapper}>
          <Input
            label="Workgroup Name"
            dataTestId="edit-workgroup-name"
            className={ModalStyles.input}
            name="saveWorkgroupName"
            onChange={(newValue: string) => handleChange(newValue)}
            value={value}
            required
            maxLength={30}
            error={error}
          />
        </Content>
      </GenericModal>
    );
  }
);

export const ModalStyles = stylesheet({
  input: {
    color: colours.primaryText,
    maxWidth: "100% !important",
  },
  contentWrapper: {
    background: colours.white,
    position: "relative",
    minWidth: 450,
  },
  header: {
    color: colours.primaryText,
    padding: "24px 24px 24px 24px",
  },
  headerTitle: {
    padding: "7px 0 0 10px",
  },
  closeIconButton: {
    position: "absolute",
    right: "24px",
  },
  footerWrapper: {
    width: "100%",
    padding: "24px 24px 24px 24px",
  },
  bodyWrapper: {
    width: "100%",
    overflowY: "auto",
    padding: "0 24px 0 24px",
  },
});