import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import useDevice from "hooks/useDevice";
import { MutableRefObject, RefObject, useEffect, useState } from "react";

export function usePageScroll(
  boundingElementRef: RefObject<HTMLDivElement>,
  elementRef: RefObject<HTMLDivElement>,
  showScrollPosition = 10
) {
  const [showOnScroll, setShowOnScroll] = useState(false);
  const [height, setHeight] = useState(0);
  const { dimension } = useDevice();

  useEffect(() => {
    setTimeout(() => {
      const rect = boundingElementRef.current?.getBoundingClientRect();
      if (rect) {
        const page = boundingElementRef.current?.parentElement?.parentElement;
        const height = dimension.height - rect.y - (page?.scrollTop || 0) - 20;

        setHeight(height);
      }
    }, 100);
  }, [boundingElementRef, dimension]);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > showScrollPosition;
      if (isShow !== showOnScroll) 
        setShowOnScroll(isShow);
    },
    [showOnScroll],
    elementRef as MutableRefObject<HTMLDivElement>,
    false,
    300,
    boundingElementRef as MutableRefObject<HTMLDivElement>
  );

  const handleScroll = () => {
    boundingElementRef.current?.scrollTo({
      behavior: "smooth",
      top: 0,
    });
  };

  return {
    handleScroll,
    height,
    showOnScroll,
  };
}
