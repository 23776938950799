import { Radio } from "antd";
import { ComponentSchema } from "../Renderer/Renderer";
import { Input, InputDefaultProps, InputOptions } from "./Input/Input";
import { InputNumber, InputNumberDefaultProps, InputNumberOptions } from "./InputNumber/InputNumber";
import { StepperControl, StepperControlDefaultProps, StepperControlOptions } from "./StepperControl/StepperControl";
import { RadioDefaultProps, RadioOptions } from "./Radio/Radio";
import { SearchableSelect, SearchableSelectOptions } from "./Select/SearchableSelect";
import { Select, SelectDefaultProps, SelectOptions } from "./Select/Select";
import { MultiSelect, MultiSelectOptions } from "./Select/MultiSelect";
import { Checklist, ChecklistDefaultProps, ChecklistOptions } from "./Checklist/Checklist";
import { CheckBox, CheckBoxDefaultProps, CheckBoxOptions } from "./CheckBox/CheckBox";
import { Toggle, ToggleDefaultProps, ToggleOptions } from "./Toggle/Toggle";
import { TimePicker, TimePickerOptions } from "./Time/TimePicker";
import MultiSelectCheckbox, { MultiSelectCheckboxOptions } from "./Select/MultiSelectCheckbox";

export type ComponentData = {
  component: React.FC<any>;
  options: ComponentSchema;
  defaultProps: Record<string, unknown>;
};

export const map = new Map<string, ComponentData>([
  [
    "Input",
    {
      component: Input,
      options: InputOptions,
      defaultProps: InputDefaultProps,
    },
  ],
  [
    "InputNumber",
    {
      component: InputNumber,
      options: InputNumberOptions,
      defaultProps: InputNumberDefaultProps,
    },
  ],
  [
    "StepperControl",
    {
      component: StepperControl,
      options: StepperControlOptions,
      defaultProps: StepperControlDefaultProps,
    },
  ],
  [
    "Radio",
    {
      component: Radio,
      options: RadioOptions,
      defaultProps: RadioDefaultProps,
    },
  ],
  [
    "Select",
    {
      component: Select,
      options: SelectOptions,
      defaultProps: SelectDefaultProps
    },
  ],
  [
    "SearchableSelect",
    {
      component: SearchableSelect,
      options: SearchableSelectOptions,
      defaultProps: SelectDefaultProps
    },
  ],
  [
    "MultiSelect",
    {
      component: MultiSelect,
      options: MultiSelectOptions,
      defaultProps: SelectDefaultProps,
    },
  ],
  [
    "Checklist",
    {
      component: Checklist,
      options: ChecklistOptions,
      defaultProps: ChecklistDefaultProps,
    },
  ],
  [
    "CheckBox",
    {
      component: CheckBox,
      options: CheckBoxOptions,
      defaultProps: CheckBoxDefaultProps,
    },
  ],
  [
    "Toggle",
    {
      component: Toggle,
      options: ToggleOptions,
      defaultProps: ToggleDefaultProps,
    },
  ],
  [
    "TimePicker",
    {
      component: TimePicker,
      options: TimePickerOptions,
      defaultProps: {},
    },
  ],
  [
    "MultiSelectCheckbox",
    {
      component: MultiSelectCheckbox,
      options: MultiSelectCheckboxOptions,
      defaultProps: {},
    },
  ],
]);
