import { Horizontal } from "gls/lib";
import { colours } from "styling/colours";
import { classes, style } from "typestyle";

type HelperTextProps = {
    content: string,
    className ?: string,
}
export const HelperText = ({content, className} : HelperTextProps) => {

    return (
        <Horizontal verticalAlign="center" spacing={6} className={classes(helperTextStyle, className)}>
          <span key={`baseinput-helper-text`}>{content}</span>
        </Horizontal>
      );
}

const helperTextStyle = style({
    fontWeight: "normal",
    fontSize: "12px",
    color: colours.secondaryText,
    marginTop: ".3rem",
    maxWidth: "100%",
  });