import { useState } from "react";
import { WorkgroupDto } from "../WorkgroupDto";
import { useWorkgroupsStore } from "contexts/RootStore.context";
import { alertErrorMessage, alertSuccessMessage } from "utils/alerts";

export function useWorkgroups() {

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [updateNameModalOpen, setUpdateNameModalOpen] = useState(false);
  const { currentWorkgroup, setCurrentWorkgroup, updateWorkgroupName, apiLoading, deleteWorkgroup } = useWorkgroupsStore();

  const renderUnknownError = () => alertErrorMessage("An unknown error occurred")

  const changeWorkgroupName = (workgroupName: string) => {
    if (currentWorkgroup) {
      updateWorkgroupName(workgroupName)
        .then(() => {
          alertSuccessMessage("Changes saved for the workgroup")
          setUpdateNameModalOpen(false);
        })
        .catch(() => {
          renderUnknownError()
        });
    }
  };

  const setDeleteError = () => {
    renderUnknownError();
    setDeleteOpen(false);
    setIsDeleting(false);
  };

  const workgroupDelete = () => {
    if (currentWorkgroup) {
      setIsDeleting(true);
      deleteWorkgroup()
        .then((response) => {
          setDeleteOpen(false);
          setIsDeleting(false);
          alertSuccessMessage(`${currentWorkgroup.name} workgroup and the roles associated with it have been deleted`)
        })
        .catch(() => {
          setDeleteError();
        });
    }
  };

  const handleOnEdit = (workgroup: WorkgroupDto) => {
    setUpdateNameModalOpen(true);
    setCurrentWorkgroup(workgroup);
  };

  const handleEditOnClose = () => {
    setUpdateNameModalOpen(false);
    setCurrentWorkgroup(undefined);
  };

  return {
    changeWorkgroupName,
    currentWorkgroup,
    deleteOpen,
    handleEditOnClose,
    handleOnEdit,
    isDeleting,
    setCurrentWorkgroup,
    setDeleteOpen,
    updateNameModalOpen,
    workgroupDelete,
    apiLoading,
  };
}
