import { Vertical } from "gls/lib";
import React from "react";
import { CheckBox } from "../CheckBox/CheckBox";
import { ComponentSchema } from "components/Renderer/Renderer";

export type ChecklistOption = { key: string; value: string, disabled?: boolean };

export type ChecklistProps = {
  ariaLabel?: string;
  label?: string;
  name: string;
  onChange: (v: Record<string, boolean>) => void;
  options: ChecklistOption[];
  optionsValue: Record<string, boolean>;
  helperText?: string;
};

export const ChecklistDefaultProps = {
  label: "Checklist",
  options: [
    { key: "Option A", value: "a" },
    { key: "Option B", value: "b" },
  ],
  defaultValue: "a",
};

export const Checklist: React.FC<ChecklistProps> = ({
  label,
  name,
  onChange,
  options,
  optionsValue,
  helperText,
}) => {
  if (!optionsValue) {
    optionsValue = options.reduce((acc, curr) => {
      return { ...acc, [curr.value]: false };
    }, {});
  }

  const handleChange = (evt: React.FormEvent<HTMLInputElement>) => {
    const key = evt.currentTarget.value;
    const innerValue = Boolean(optionsValue && optionsValue[key]);
    const newValue = { ...optionsValue, [key]: !innerValue };
    onChange(newValue);
  };

  return (
    <Vertical spacing={8}>
      {label && <label htmlFor={name}>{label}</label>}
      {options.map((option, index) => (
        <CheckBox
          key={`checkbox-${option.value}-${index}`}
          checked={optionsValue[option.value]}
          value={option.value}
          label={option.key}
          name={name}
          onChange={handleChange}
          disabled={option.disabled}
        />
      ))}
    </Vertical>
  );
};

export const ChecklistOptions: ComponentSchema = {
  label: "Checkbox",
  type: "Checkbox",
  icon: "ChecklistIcon",
  propsSchema: [
    {
      id: "label",
      type: "Input",
      props: {
        label: "Label",
      },
    },
    {
      id: "options",
      type: "KeyValuePairs",
      props: {
        label: "Key/Value Pairs",
      },
    },
    {
      id: "ariaLabel",
      type: "Input",
      props: {
        label: "Aria Label",
      },
    },
  ],
};
