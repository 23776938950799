import { Label } from "components/Label";
import { Horizontal, Vertical } from "gls/lib";
import React from "react";
import { classes, style } from "typestyle";
import { radioButtonStyle } from "./radio.styles";
import { colours } from "styling/colours";
import { ComponentSchema } from "components/Renderer/Renderer";
import { KeyValuePair } from "utils/common";

export interface RadioOption extends KeyValuePair<string | number> {
  disabled?: boolean;
  postfixMsg?: string;
}
export type RadioProps = {
  value?: any;
  label?: string;
  onChange: (value: any) => void;
  name?: string;
  disabled?: boolean;
  required?: boolean;
  options: RadioOption[];
  direction?: "horizontal" | "vertical";
  ariaLabel?: string;
  helperText?: string;
  itemClassName?: string;
};

export const RadioOptions: ComponentSchema = {
  label: "Radio",
  type: "Radio",
  icon: "RadioIcon",
  propsSchema: [
    {
      id: "label",
      type: "Input",
      props: {
        label: "Label",
      },
    },
    {
      id: "options",
      type: "KeyValuePairs",
      props: {
        label: "Key/Value Pairs",
      },
    },
    {
      id: "ariaLabel",
      type: "Input",
      props: {
        label: "Aria Label",
      },
    },
  ],
};

export const RadioDefaultProps = {
  label: "Radio",
  options: [
    { key: "Option A", value: "a" },
    { key: "Option B", value: "b" },
  ],
  defaultValue: "a",
};

export const Radio: React.FC<RadioProps> = (props: RadioProps) => {
  const { name, value, options, onChange, ariaLabel = "Radio Input", itemClassName } = props;
  const direction = props.direction || "horizontal";

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    onChange(evt.currentTarget.value);
  };

  const content =
    options &&
    options.map((option, index) => {
      const id = `${name || "radio"}-${option.value}`;

      return (
        <Horizontal
          spacing={0}
          verticalAlign="center"
          key={`radio-${option.value}-${index}`}
          className={classes(radioButtonStyle, itemClassName)}
          aria-label={ariaLabel}
          id={ariaLabel}
        >
          <input
            type="radio"
            id={id}
            name={name}
            checked={option.value === value}
            value={option.value}
            onChange={handleChange}
            className={classes(`${ariaLabel} Input`, inputStyle)}
            aria-label={`${ariaLabel} Input`}
            disabled={props.disabled || option.disabled}
          />
          <label htmlFor={id} className={option.disabled ? disableOptionStyle : ""}>
            {option.key}
            {option.postfixMsg && <span className={disableOptionStyle}>{option.postfixMsg}</span>}
          </label>
        </Horizontal>
      );
    });

  return (
    <Vertical spacing={0}>
      {props.label && <Label value={props.label} />}
      <DirectionComponent direction={direction}>{content}</DirectionComponent>
    </Vertical>
  );
};

type DirectionComponentProps = {
  direction: "horizontal" | "vertical";
  children: React.ReactNode;
};

const DirectionComponent = (props: DirectionComponentProps) => {
  let result = (
    <Horizontal spacing={30} padding={{ top: "10px" }}>
      {props.children}
    </Horizontal>
  );

  if (props.direction === "vertical") {
    result = (
      <Vertical
        spacing={15}
        padding={{
          top: "10px",
        }}
      >
        {props.children}
      </Vertical>
    );
  }

  return result;
};

const inputStyle = style({
  width: "20px",
  height: "20px",
});

const disableOptionStyle = style({
  color: colours.secondaryText,
})