import { Label } from "components/Label";
import { Content, Vertical } from "gls";
import { isArray, isEmpty } from "lodash";
import ReactSelect, { components, OptionProps } from "react-select";
import { classes } from "typestyle";
import { Error } from "../Error";
import {
  dropIconStyle,
  selectContainerStyles,
  selectHiddenStyle,
  selectStyles,
  selectTheme,
} from "./selectStyles";
import { ReactElement, useEffect, useMemo, useState } from "react";
import useDevice, { MIN_SELECT_MENU_HEIGHT } from "hooks/useDevice";
import { ComponentSchema } from "components/Renderer/Renderer";


type SelectProps = {
  id?: string;
  isLoading?: boolean;
  label?: string | ReactElement;
  name?: string;
  value: string | number | null | boolean | undefined;
  options: SelectOption[] | SelectOptionGroups[];
  required?: boolean;
  className?: string;
  placeholder?: string;
  showPlaceholderOnDisabled?: boolean;
  ariaLabel?: string;
  onChange: (v: any) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  disabled?: boolean;
  width?: string;
  dataTestId?: string;
  error?: string | string[];
  hideErrorText?: boolean;
  hidden?: boolean;
  isClearable?: boolean;
  helperText?: string;
  setOnlyOptionAsValue?: boolean;
};

export type SelectOption = {
  key: string;
  value: string | number | boolean;
  hidden?: boolean;
}
export type SelectOptionGroups = {
  label?: string;
  options: SelectOption[]
} | SelectOption[];

type OptionType = {
  label: string;
  value: string | number | boolean;
  hidden?: boolean;
};
type OptionGroup = {
  label?: string,
  index?: number,
  options: OptionType[]
};

const Option = (props: OptionProps<OptionType, any>) => {

  return (
    props.data.hidden ? <></> :
      <div>
        <components.Option {...props}>
          <Content verticalAlign="center">
            <label>{props.label}</label>
          </Content>
        </components.Option>
      </div>
  );
};

function getOptions(options: SelectOption[] | SelectOptionGroups[]): OptionType[] | OptionGroup[] {
  const ValidOption = (o: SelectOption) => ({ label: o.key, value: o.value, hidden: o.hidden })
  if (options.length === 0)
    return [];
  if ("value" in options[0]) {
    return (options as SelectOption[]).map(ValidOption);
  }
  return options.map((r, index) => {
    return {
      label: "label" in r ? r.label : "",
      index: index,
      options: "options" in r ? r.options.map(ValidOption) : (isArray(r) ? r : []).map(ValidOption)
    }
  })
}

export const SearchableSelect = ({ options: propOptions = [], ...props }: SelectProps) => {
  // Maintain same API as native <select />
  // This calculations can be removed if there's no need for having the same API as native <select />

  const { setOnlyOptionAsValue = true } = props;
  const ariaLabel = props.ariaLabel || props.id || "Searchable Select";
  const { isMobileDevice: isMobileWidth } = useDevice();
  const [selectValue, setSelectValue] = useState<OptionType | null>(null);

  const handleChange = (v: any) => {
    if (v === null) {
      props.onChange(v);
      return;
    }
    const value = (v as OptionType).value;
    props.onChange(value);
  };
  const buildIds = () => props.dataTestId ? `${ariaLabel} Input ${props.dataTestId || ''}` : `${ariaLabel} Input`
  const inputId = props.id
    ? `${props.id}-input`
    : props.dataTestId
      ? `${ariaLabel} Input ${props.dataTestId || ''}`
      : `${ariaLabel} Input`;

  const hidden = props.hidden ?? false;
  const placeHolder = !props.disabled
    ? props.placeholder || "Enter the Keyword"
    : props.showPlaceholderOnDisabled
      ? props.placeholder
      : " "
  const options = useMemo(() => {
    return getOptions(propOptions);
  }, [propOptions])

  useEffect(() => {
    if (props.value) {
      let val = options.map(o => "options" in o ? o.options : o).flat().find(o => o.value === props.value)
      if (val === undefined) {
        props.onChange(undefined);
      }
      setSelectValue(val || null);
    }
    else {
      setSelectValue(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, props.value])


  const getDefaultOption = () => {
    if (setOnlyOptionAsValue && !props.disabled && props.required && options.length === 1) {
      if ("options" in options[0]) {
        if (options[0].options.length === 1) {
          let val = options[0].options[0];
          return val.value;
        }
      }
      else {
        let val = options[0];
        return val.value;
      }
    }
    return undefined;
  }

  useEffect(() => {
    if (selectValue === null && !props.value) {
      const defaultOption = getDefaultOption();
      if(defaultOption)
        props.onChange(defaultOption)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, props.value, selectValue, setOnlyOptionAsValue])


  const errors = props.error ? (isArray(props.error) ? props.error : [props.error]) : null;

  return (
    <Vertical
      spacing={0}
      id={props.id || ariaLabel}
      className={classes(
        selectContainerStyles(props.width, isMobileWidth),
        props.className,
        selectHiddenStyle(hidden)
      )}
      aria-label={ariaLabel}
      data-testid={props.dataTestId}
    >
      {props.label && (
        <Label
          htmlFor={props.name}
          value={props.label}
          required={props.required}
        />
      )}
      <ReactSelect
        options={options}
        components={{ Option }}
        theme={selectTheme}
        styles={selectStyles(isMobileWidth, false, false, undefined, !isEmpty(props.error))}
        value={selectValue}
        onChange={handleChange}
        menuPortalTarget={document.body}
        placeholder={placeHolder}
        menuPlacement="auto"
        isDisabled={props.disabled}
        className={dropIconStyle(props.disabled)}
        classNamePrefix="scrollable"
        closeMenuOnScroll={(e) =>
          (e.target as HTMLElement).className.indexOf("scrollable") === -1
        }
        id={buildIds()}
        inputId={inputId}
        aria-label={`${ariaLabel} Input`}
        minMenuHeight={isMobileWidth ? undefined : MIN_SELECT_MENU_HEIGHT}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        isLoading={props.isLoading}
        isClearable={props.isClearable ?? (!props.required ?? true)}
      />
      {errors && !props.hideErrorText &&
        errors.map((e, i) => (
          <Error
            key={`ReactSelect-err-${inputId}`}
            id={`ReactSelect-err-${inputId}`}
            message={e}
          />
        ))}
    </Vertical>
  );
};

export const SearchableSelectOptions: ComponentSchema = {
  label: "Searchable Select",
  type: "SearchableSelect",
  icon: "SelectIcon",
  propsSchema: [
    {
      id: "label",
      type: "Input",
      props: {
        label: "Label",
      },
    },
    {
      id: "placeholder",
      type: "Input",
      props: {
        label: "Placeholder",
      },
    },
    {
      id: "options",
      type: "KeyValuePairs",
      props: {
        label: "Key/Value Pairs",
      },
    },
    {
      id: "ariaLabel",
      type: "Input",
      props: {
        label: "Aria Label",
      },
    },
    {
      id: "alignment",
      type: "Select",
      defaultValue: "vertical",
      props: {
        label: "alignment",
      },
    },
  ],
  validationSchema: [
    {
      id: "required",
      type: "Toggle",
      props: {
        label: "Required",
      },
    },
  ],
};