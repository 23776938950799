import { icons } from "assets/icons";
import { ChangeMap, Renderer } from "components/Renderer/Renderer";
import { Vertical } from "gls";
import { SortableContainer, SortableContainerProps } from "react-sortable-hoc";
import { classes, style } from "typestyle";
import { ComplexListProps } from "./ComplexList";
import OrderableListItem from "./OrderableListItem";
import { cloneDeep } from "lodash";
import { useDeepCompareMemo } from "use-deep-compare";
import { SchemaProvider } from "./helpers/schema";
import { ComponentClass } from "react";
import useDevice from "hooks/useDevice";
import IconButton from "components/Button/IconButton";


type OrderableListProps = SortableContainerProps & ComplexListProps & {
  handleChange: (
    value: Record<string, unknown>,
    index: number,
    errors?: Record<string, string[]>,
    onChangeMap?: Record<string, ChangeMap>
  ) => void;
  handleDeleteItem: (index: number) => void;
  isOpen: boolean;
  handleBlur: (index: number) => void;
};

let deleteIndex = -1;

const OrderableList: ComponentClass<OrderableListProps, any> = SortableContainer((props: OrderableListProps) => {
  const {
    value,
    errors = {},
    onChangeMap = {},
    itemPropsSchema,
    itemPropsSchemaOverride = {},
    editable,
    minItems = 1,
    handleChange,
    handleBlur,
    handleDeleteItem,
    isOpen,
    ariaLabel = "Complex List",
  } = props;
  const { isMobileDevice } = useDevice();
  const deleteIconSize = isMobileDevice ? 30 : 40;

  const propSchemaPerListItem = useDeepCompareMemo(() => {
    const schemaProvider = new SchemaProvider(itemPropsSchema, itemPropsSchemaOverride);
    return value.map((_, i) => cloneDeep(schemaProvider.get(i)));
  }, [value.length, itemPropsSchema, itemPropsSchemaOverride]);

  return (
    <Vertical
      spacing={40}
      aria-label={`${ariaLabel} Items`}
      id={`${ariaLabel} Items`}
    >
      {isMobileDevice && value.length > 1 && <span></span>}
      {value.map((v, i) => (
        <OrderableListItem
          index={i}
          idx={i + 1}
          key={`list-${i}`}
          nItems={value.length}
          isMobileWidth={isMobileDevice}
          item={
            <Vertical
              className={classes(
                itemWrapperStyle(value.length > 1),
                isOpen && i === deleteIndex ? style(hoverStyle()) : ""
              )}
              spacing={0}
            >
              <Renderer
                schema={propSchemaPerListItem[i] || []}
                values={v}
                onChange={(value, errors, newOnChangeMap) => {
                  handleChange(value, i, errors, newOnChangeMap);
                }}
                onBlur={() => { handleBlur(i); }}
                errors={errors}
                onChangeMap={onChangeMap}
                index={i}
                showInitialValidation
              />
              {editable && value.length > minItems && (
                <IconButton
                  className={
                    isMobileDevice ? removeButtonStyleMobile : removeButtonStyle
                  }
                  onClick={() => handleDeleteItem(i)}
                  title="Delete"
                  icon={
                    <icons.ListDeleteIcon
                      width={deleteIconSize}
                      height={deleteIconSize}
                    />
                  }
                />
              )}
            </Vertical>
          }
          canRearrange={props.canRearrange}
        />
      ))}
    </Vertical>
  );
});

export default OrderableList;

const hoverStyle = (hover = true) => {
  return {
    background: hover ? "#f5f6fa" : "#fff",
    button: {
      visibility: "visible",
    },
  };
};

const itemWrapperStyle = (hover = true) =>
  style({
    width: 0,
    flexGrow: 1,
    padding: "17px 0 27px 0",
    position: "relative",
    border: "1px dashed #dadada",
    backgroundColor: "#fff",
    $nest: {
      "&:hover": hoverStyle(hover),
    },
  });

const removeButtonStyle = style({
  position: "absolute",
  borderRadius: "50%",
  top: "-20px",
  right: "-20px",
  visibility: "hidden",
});

const removeButtonStyleMobile = style({
  position: "absolute",
  borderRadius: "50%",
  top: -37,
  right: 0,
});
